import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Portfolio from "./pages/Portfolio";
import Trade from "./pages/Trade";
import TradeStep1 from "./pages/TradeStep1";
import Match from "./pages/Match";
import Account from "./pages/Account";
import axios from "axios";
import MatchAdmin from "./admin/MatchAdmin";
import CompDeets from "./components/CompDeets";
import NotFound from "./pages/NotFound";

import Landing from "./landing/Landing.jsx";
import Leaderboard from "./pages/Leaderboard";

function App() {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    axios
      .get(
        "https://apiv5.paraswap.io/prices?srcToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&srcDecimals=18&destToken=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&destDecimals=6&amount=1000000000000000000&side=SELL&network=1 "
      )
      .then((data) => {
        setPrice(data?.data?.priceRoute?.destUSD);
      });
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home /* ethPrice={price} */ />} />
        <Route path="/app/signup" element={<SignUp />} />
        <Route path="/app/login" element={<Login />} />
        <Route path="/app" element={<Portfolio />} />
        <Route path="/app/portfolio" element={<Portfolio />} />
        <Route path="/app/trade" element={<Trade />} />
        <Route path="/app/trade/step1" element={<TradeStep1 />} />
        <Route path="/app/match" element={<Match />} />
        <Route path="/app/account" element={<Account price={price} />} />
        <Route path="/app/admin" element={<MatchAdmin price={price} />} />
        <Route path="/app/leaderboard" element={<Leaderboard />} />
        <Route path="/competitions/:competitionId" element={<CompDeets />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
