import {
  Box,
  Flex,
  Image,
  HStack,
  Link,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import DarkModeButton from "./DarkModeButton";
import "../App.css";
import logo from "../images/matchtrade.svg";
import darklogo from "../images/matchtradewhite.svg";

const Header = () => {
  const { colorMode } = useColorMode();
  return (
    <>
      <Flex w="100%" alignItems="center" justifyContent="center" p="10px">
        <Box
          w={{ base: "95%", md: "50%" }}
          p="12px"
          borderRadius="20px"
          border="1px solid rgba(209, 213, 219, 0.3)"
          backgroundColor="rgba(255, 255, 255, 0.75)"
          _dark={{
            bg: "rgba(17, 25, 40, 0.75)",
            border: "1px solid rgba(255, 255, 255, 0.125)",
          }}
          style={{
            backdropFilter: "blur(16px) saturate(180%)",
            WebkitBackdropFilter: "blur(16px) saturate(180%)",
          }}
        >
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <HStack>
              {colorMode == "light" ? (
                <Image src={logo} boxSize="20px" />
              ) : (
                <Image src={darklogo} boxSize="20px" />
              )}
              <Box fontSize="18px">MatchTrade</Box>
            </HStack>

            <HStack>
              <Link href="/app/login" _hover={{ textDecoration: "none" }}>
                <Button
                  display={{ base: "none", md: "flex" }}
                  borderRadius="10px"
                  size="sm"
                >
                  Sign in
                </Button>
              </Link>
              <Link href="/app/signup" _hover={{ textDecoration: "none" }}>
                <Button size="sm" borderRadius="10px">
                  Sign up
                </Button>
              </Link>
              <DarkModeButton size="sm" borderRadius="10px" />
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Header;
