import React from "react";
import AppHeader from "../components/AppHeader";
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorMode,
} from "@chakra-ui/react";
import VolumeComp from "./VolumeComp";
import PnLComp from "./PnLComp";
import NumberComp from "./NumberComp";

const Match = () => {
  const { colorMode } = useColorMode();

  return (
    <div>
      <AppHeader />
      <Tabs variant="unstyled">
        <Flex w="100%" alignItems="center" justifyContent="center">
          <TabList
            border={
              colorMode == "light"
                ? "1px solid rgba(34, 34, 34, 0.07)"
                : "2px solid #15161a"
            }
            p="4px"
            borderRadius="16px"
          >
            <Tab
              borderRadius="12px"
              _selected={{
                bg: colorMode == "light" ? "#efefef" : "#242424",
                borderRadius: "12px",
              }}
            >
              Volume
            </Tab>
            <Tab
              borderRadius="12px"
              _selected={{
                bg: colorMode == "light" ? "#efefef" : "#242424",
                borderRadius: "12px",
              }}
            >
              PnL
            </Tab>
            <Tab
              borderRadius="12px"
              _selected={{
                bg: colorMode == "light" ? "#efefef" : "#242424",
                borderRadius: "12px",
              }}
            >
              Number
            </Tab>
          </TabList>
        </Flex>

        <TabPanels>
          <TabPanel mt="20px" /* bg="red" */ w="100%">
            <VolumeComp />
          </TabPanel>
          <TabPanel>
            <PnLComp />
          </TabPanel>
          <TabPanel>
            <NumberComp />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Match;
