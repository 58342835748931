import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Alert,
  AlertIcon,
  Box,
  Flex,
  Image,
  HStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import axios from "axios";
import { fetchUserByAddress } from "../helpers/getUserData";

const UpdateTradeVolumeModal = ({ isOpen, onClose, value, swapData }) => {
  const [userEyeDee, setUserEyeDee] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [pnlArray, setPnlArray] = useState([]);
  const [pnl, setPnl] = useState(0);
  const { colorMode } = useColorMode();
  const { address } = useAccount();
  const [comps, setComps] = useState([]);
  const [comps2, setComps2] = useState([]);
  const [comps3, setComps3] = useState([]);
  const [sortedComps, setSortedComps] = useState([]);
  const [tradeId, setTradeId] = useState(0);
  const [type, setType] = useState(0);
  const toast = useToast();
  const [userCompTrades, setUserCompTrades] = useState([]);

  const ca = "0x8d2Ff13de8C05605d7dd9998c224E129865d021A";
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [abi, setAbi] = useState([]);
  const [contract, setContract] = useState("");
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await axios
          .get("https://serverrrrr-0tkv.onrender.com/api/getABI", {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          })
          .then((data) => setAbi(data.data.abi));
      } catch (e) {}
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    const newContract = new ethers.Contract(ca, abi, signer);
    setContract(newContract);
  }, [abi]);
  const fetchAllChallenges = async () => {
    const interval = setInterval(async () => {
      try {
        const provider2 = new ethers.providers.JsonRpcProvider(
          "https://eth-mainnet.g.alchemy.com/v2/aNQ92qIHl_o0nmZklF4di1fWdQmI2hde"
        );
        const contracte = new ethers.Contract(ca, abi, provider2);
        const challenges = await contracte.getCompetitionsByType(0);
        const challenges2 = await contracte.getCompetitionsByType(1);
        const challenges3 = await contracte.getCompetitionsByType(2);

        setComps(challenges);
        setComps2(challenges2);
        setComps3(challenges3);
      } catch (e) {}
    }, 5000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    fetchAllChallenges();
  }, [contract]);

  useEffect(() => {
    if (comps) {
      try {
        const competitionObjects = comps.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: competition.endTime,
          challengeAmount: competition.challengeAmount,
          creatorVolume: competition.creatorVolume,
          challengerVolume: competition.challengerVolume,
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: competition.competitionId,
          compTrades: [],
        }));

        const competitionObjects2 = comps2.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: competition.endTime,
          challengeAmount: competition.challengeAmount,
          creatorVolume: competition.creatorVolume,
          challengerVolume: competition.challengerVolume,
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: competition.competitionId,
          compTrades: [],
        }));

        const competitionObjects3 = comps3.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: competition.endTime,
          challengeAmount: competition.challengeAmount,
          creatorVolume: competition.creatorVolume,
          challengerVolume: competition.challengerVolume,
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: competition.competitionId,
          compTrades: [],
        }));

        const newArray = competitionObjects.concat(
          competitionObjects2,
          competitionObjects3
        );

        setSortedComps(newArray);
      } catch (e) {}
    }
  }, [comps]);

  const filteredArray = sortedComps.filter(
    (obj) =>
      String(obj.creator) === String(address) ||
      String(obj.challenger) === String(address)
  );

  const [selectedId, setSelectedId] = useState(null);

  const handleSelect = (id) => {
    setSelectedId(id === selectedId ? null : id);
  };

  // Handler function to update the trade volume
  const handleUpdateVolume = async () => {
    try {
      setIsUpdating(true);

      await contract.updateTradeVolume(
        String(tradeId),
        Math.ceil(Number(value))
      );
      toast({
        title: "Updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsUpdating(false);
    } catch (e) {
      toast({
        title: "Update failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsUpdating(false);
    }
  };

  function formatTimestamp(unixTimestamp) {
    const time = moment.unix(unixTimestamp);
    const formattedTime = time.format("ddd Do, HH:mm a");
    return formattedTime;
  }

  /* console.log(swapData); */

  const updateDetails = {
    compId: String(tradeId),
    pair: `${swapData?.action?.fromToken?.symbol}/${swapData?.action?.toToken?.symbol}`,
    pairRates: [
      {
        fromToken: swapData?.action?.fromToken?.priceUSD,
        toToken: swapData?.action?.toToken?.priceUSD,
        rate:
          Number(
            swapData?.estimate?.fromAmount /
              10 ** swapData?.action?.fromToken?.decimals
          ) /
          Number(
            swapData?.estimate?.toAmount /
              10 ** swapData?.action?.toToken?.decimals
          ),
      },
    ],
    pairMeta: [
      {
        fromToken: swapData?.action?.fromToken?.logoURI,
        toToken: swapData?.action?.toToken?.logoURI,
      },
    ],
    pairAmounts: [
      {
        fromToken: Number(
          swapData?.estimate?.fromAmount /
            10 ** swapData?.action?.fromToken?.decimals
        ),
        toToken: Number(
          swapData?.estimate?.toAmount /
            10 ** swapData?.action?.toToken?.decimals
        ),
      },
    ],
  };

  /*  useEffect(() => {
    console.log(updateDetails);
    console.log(swapData);
  }, [swapData]); */

  const [wording, setWording] = useState("volume");

  useEffect(() => {
    if (type == "1") {
      setWording("PnL");
    } else if (type == "2") {
      setWording("Number");
    } else if (type == "0") {
      setWording("Volume");
    }
  }, [type]);

  const updateDetailsOnServer = async () => {
    try {
      const response = await axios.post(
        `https://serverrrrr-0tkv.onrender.com/api/addTradetoUserCompTrades`,
        {
          userId: userEyeDee,
          userTrades: updateDetails,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // Optionally, handle the response data or perform additional actions
    } catch (error) {}
  };

  useEffect(() => {
    const getDetails = async () => {
      const eyedee = await fetchUserByAddress(address);
      setUserCompTrades(eyedee?.userCompTrades);
      setUserEyeDee(eyedee?._id);
    };
    getDetails();
  }, [address]);

  function calculatePnL(dataArray) {
    const resultArray = [];

    dataArray.forEach((obj) => {
      const pairRates = obj.pairRates;
      const pairAmounts = obj.pairAmounts;

      // Step 1: Calculate weighted average rate
      const weightedAverageRate =
        pairRates.reduce((sum, rateObj) => {
          const rate = parseFloat(rateObj.rate);
          const fromToken = parseFloat(pairAmounts[0].fromToken);
          return sum + rate * fromToken;
        }, 0) /
        pairAmounts.reduce(
          (sum, amount) => sum + parseFloat(amount.fromToken),
          0
        );

      // Step 2: Multiply the last recorded value of pairRate.rate
      const lastRate = parseFloat(pairRates[pairRates.length - 1].rate);
      const multipliedByLastRate = lastRate * weightedAverageRate;

      // Step 3: Multiply by the sum of all quantities
      const sumOfQuantities = pairAmounts.reduce(
        (sum, amount) => sum + parseFloat(amount.fromToken),
        0
      );
      const finalValue = multipliedByLastRate * sumOfQuantities;

      // Step 4: Calculate the percentage change
      const cumulativePercentageChange =
        ((lastRate - weightedAverageRate) / weightedAverageRate) * 100;

      const pnl = (cumulativePercentageChange / 100) * sumOfQuantities;

      // Step 5: Push the results into the resultArray
      resultArray.push({
        compId: obj.compId,
        pnl: finalValue,
        pair: obj.pair,
        percPnL: cumulativePercentageChange,
      });
    });

    return resultArray;
  }

  const runCheck = () => {
    const result = calculatePnL(userCompTrades); //fetch from the DB instead
    setPnlArray(result);
  };

  useEffect(() => {
    runCheck();
  }, [updateDetails]);

  useEffect(() => {
    const pnlValues = pnlArray.map((obj) => obj.pnl);
    const averagePnl =
      pnlValues.reduce((sum, value) => sum + value, 0) / pnlValues.length;
    setPnl(averagePnl);
  }, [pnlArray]);

  const handleUpdatePnL = async () => {
    try {
      await contract.updatePnL(String(tradeId), Number(Math.floor(pnl)));
      toast({
        title: "Updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "Update failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="15px">
          <ModalHeader>Update Trade Details</ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <Alert borderRadius="10px" status="info">
              <AlertIcon />
              Select a competition to allocate your volume or PnL number to.
            </Alert>

            <Alert mt="10px" borderRadius="10px" status="warning">
              <AlertIcon />
              To ensure security, updating volume is an onchain action and will
              cost a little gas.
            </Alert>

            <Box mt="20px" fontSize="20px">
              Your competitions
            </Box>
            <Box
              mt="10px"
              p="20px"
              h="300px"
              borderRadius="20px"
              w="100%"
              border="2px dashed #f5f5f7"
              borderStyle="dashed 10px"
              _dark={{ border: "2px dashed #2b2b2b " }}
              overflowY="scroll"
            >
              {filteredArray?.length == 0 ? (
                <>
                  <Box>
                    <Flex
                      w="100%"
                      mt="50px"
                      flexDir="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image src="https://investdex.io/static/media/lock_Icon.2406b9f1.svg" />
                      <Box mt="30px" color="gray.500">
                        You haven't challenged anyone.
                      </Box>
                    </Flex>
                  </Box>
                </>
              ) : (
                <>
                  {filteredArray?.map((e, i) => {
                    const isSelected = selectedId === i;
                    return (
                      <>
                        <Flex
                          _hover={{
                            cursor: "pointer",
                          }}
                          w="100%"
                          alignItems="center"
                          justifyContent="space-between"
                          mt="10px"
                          bg={
                            colorMode == "light" && isSelected
                              ? "#f5f5f7"
                              : "dark" && isSelected
                              ? "#2b2b2b"
                              : "none"
                          }
                          onClick={() => {
                            handleSelect(i);
                            setTradeId(Number(e?.index));
                            setType(e?.competitionType);
                          }}
                          borderRadius="10px"
                        >
                          <HStack>
                            <Image
                              borderRadius="10px"
                              border="5px solid #f5f5f7"
                              _dark={{ border: "5px solid #15161a" }}
                              src={`https://www.gravatar.com/avatar/${e?.challengeAmount}?d=identicon`}
                              boxSize={{ base: "40px", md: "40px" }}
                            />

                            <Flex
                              flexDir="column"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Box>
                                {ethers.utils.formatEther(
                                  String(e?.challengeAmount)
                                )}{" "}
                                ETH
                              </Box>

                              <Box color="gray.500" fontSize="12px">
                                Created by:{" "}
                                <Box
                                  mr="3px"
                                  color="black"
                                  _dark={{ color: "white" }}
                                  as="span"
                                  display="inline-block"
                                >
                                  {" "}
                                  {String(e?.creator) == String(address)
                                    ? "you"
                                    : e?.creator?.slice(0, 5) +
                                      "...." +
                                      e?.creator?.slice(-4)}{" "}
                                </Box>
                                Ends {formatTimestamp(e?.endTime)}
                              </Box>
                            </Flex>
                          </HStack>
                        </Flex>
                      </>
                    );
                  })}
                </>
              )}
            </Box>
            <Button
              isLoading={isUpdating}
              onClick={async () => {
                if (String(type) === "1") {
                  setIsUpdating(true);
                  await updateDetailsOnServer(); // run the actual blockchain updating function here instead
                  runCheck();
                  await handleUpdatePnL();
                  setIsUpdating(false);
                  window.location.reload();
                } else if (String(type) === "2") {
                  await handleUpdateVolume();
                  window.location.reload();
                } else {
                  await handleUpdateVolume();
                  window.location.reload();
                }
              }}
              w="100%"
              mt="10px"
              borderRadius="10px"
            >
              Update {wording}
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UpdateTradeVolumeModal;
