import React, { useRef } from "react";
import { Box, Image, Flex, useColorMode, Text, Stack } from "@chakra-ui/react";
import { useInView } from "framer-motion";
import section1light from "../images/section1light1.png";
import section1light2 from "../images/heroimglight2.png";

const Section1 = () => {
  const { colorMode } = useColorMode();
  const ref = useRef(null);
  const isInView = useInView(ref);

  const info = [{ title: "Trade btcoin", image: section1light }];

  return (
    <div ref={ref}>
      {" "}
      <Box
        style={{
          transform: isInView ? "none" : "translateY(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        h="300px"
        w="100%"
        position="relative"
        overflow="hidden"
        mt="200px"
      >
        <Box
          w="70%"
          h="0"
          /*  borderBottom="2px solid #00a3ff" */
          position="absolute"
          top="0"
          left="50%"
          opacity="1"
          transform="perspective(1200px)"
          bg="radial-gradient(34.3% 105.9% at 50% -33.4%, rgba(4, 170, 255, 0.75) 0%, rgba(4, 170, 255, 0) 100%);"
        />

        <Box
          position="absolute"
          top="-100px"
          left="50%"
          transform="translateX(-50%)"
          width="70%"
          height="500px"
          borderRadius="50%"
          filter="blur(50px)"
          background="radial-gradient(34.3% 105.9% at 50% -33.4%, #00a3ff 0%, rgba(4, 170, 255, 0) 100%)"
        />

        <Text
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          fontSize={{ base: "24px", md: "50px" }}
          fontWeight="600"
          textAlign="center"
        >
          Match.{" "}
          <Box
            as="span"
            display="inline-block"
            backgroundClip="text"
            color="transparent"
            backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
          >
            With anyone.
          </Box>
        </Text>
      </Box>
      <Box
        mt={{ base: "300px", md: "200px" }}
        position="absolute"
        left="50%"
        transform="translate(-50%, -50%)"
        /*   fontSize={{ base: "24px", md: "50px" }} */
        fontWeight="600"
      >
        <Stack
          spacing={{ base: "20px", md: "20px" }}
          direction={{ base: "column", md: "row" }}
        >
          <Box
            borderRadius="20px"
            p="10px"
            bg="none"
            border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
            w={{ base: "350px", md: "400px" }}
            _dark={{ bg: "#0d131e" }}
            h={{ base: "390px", md: "420px" }}
            /*  bg="radial-gradient(58.1% 25% at 50% 100%, rgb(2, 15, 39) 0%, rgb(1, 9, 24) 100%)" */
          >
            <Flex
              w="100%"
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Image
                borderRadius="10px"
                /*    src={colorMode == "light" ? section1light : section1dark} */
                src={section1light}
              />
              <Box
                mt="20px"
                fontWeight="600"
                fontSize={{ base: "22px", md: "28px" }}
                backgroundClip="text"
                color="transparent"
                backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
              >
                Challenge anyone.
              </Box>
              <Box color="gray.500" _dark={{ color: "white" }} mt="15px">
                Accept another player's challenge by matching their competition
                amount or create your own competition for others to beat.
              </Box>
            </Flex>
          </Box>

          <Box
            borderRadius="20px"
            p="10px"
            bg="none"
            border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
            w={{ base: "350px", md: "400px" }}
            _dark={{ bg: "#0d131e" }}
            h={{ base: "390px", md: "420px" }}
            /*  bg="radial-gradient(58.1% 25% at 50% 100%, rgb(2, 15, 39) 0%, rgb(1, 9, 24) 100%)" */
          >
            <Flex
              w="100%"
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Image
                borderRadius="10px"
                /*    src={colorMode == "light" ? section1light : section1dark} */
                src={section1light2}
              />
              <Box
                mt="20px"
                fontWeight="600"
                fontSize={{ base: "22px", md: "28px" }}
                backgroundClip="text"
                color="transparent"
                backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
              >
                Match Set.
              </Box>
              <Box color="gray.500" _dark={{ color: "white" }} mt="15px">
                Now trade towards the goal to beat the other person. If you win,
                you get 95% of the total pool as your reward!
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default Section1;
