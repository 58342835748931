import { Flex } from "@chakra-ui/react";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../sections/Hero";
import Section1 from "../sections/Section1";
import Section2 from "../sections/Section2";
import Section3 from "../sections/Section3";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />

      <div>
        <Section1 />
      </div>

      <div style={{ marginTop: "100px" }}>
        <Section2 />
      </div>
      <div style={{ marginTop: "100px" }}>
        <Section3 />
      </div>

      <div style={{ marginTop: "100px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
