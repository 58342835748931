import React, { useState, useEffect } from "react";
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Box,
  HStack,
  Avatar,
  ModalCloseButton,
} from "@chakra-ui/react";

import { IoChevronForward } from "react-icons/io5";
import TradeInfo from "./TradeInfo";
import GasDetails from "./GasDetails";

const SwapInfo = ({ swapData, gasInfo, swap, isSwapping, recipient }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      {/*  <TradeInfo info={swapData} />
      <GasDetails gasInfo={gasInfo} />
 */}
      <Modal
        motionPreset="slideInBottom"
        isCentered
        size={{ base: "full", md: "sm" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          bg="white"
          _dark={{ bg: { base: "black", md: "#15161a" } }}
          borderRadius="15px"
        >
          <ModalHeader /* display="flex" alignItems="flex-start" */>
            {/*   <ChevronLeftIcon onClick={onClose} /> */}
          </ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <Flex
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDir="column"
            >
              <Flex alignItems="center">
                <Box>
                  <Avatar
                    name={swapData?.action?.fromToken?.symbol}
                    src={swapData?.action?.fromToken?.logoURI}
                    borderRadius="300px"
                    boxSize="50px"
                  />
                </Box>

                <Box ml="-15px">
                  <Avatar
                    name={swapData?.action?.toToken?.symbol}
                    src={swapData?.action?.toToken?.logoURI}
                    borderRadius="300px"
                    boxSize="50px"
                  />
                </Box>
              </Flex>

              <Box mt="20px" fontSize="25px">
                Confirm swap of {swapData?.action?.fromToken?.symbol} to{" "}
                {swapData?.action?.toToken?.symbol}
              </Box>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box color="gray.500" fontSize="16px">
                  {" "}
                  Swap {swapData?.action?.fromToken?.symbol}
                </Box>

                <HStack>
                  <Avatar
                    name={swapData?.action?.fromToken?.symbol}
                    src={swapData?.action?.fromToken?.logoURI}
                    borderRadius="300px"
                    boxSize="18px"
                  />
                  <Box>
                    {isNaN(
                      Number(
                        swapData?.estimate?.fromAmount /
                          10 ** swapData?.action?.fromToken?.decimals
                      )?.toFixed(3)
                    )
                      ? "0"
                      : Number(
                          swapData?.estimate?.fromAmount /
                            10 ** swapData?.action?.fromToken?.decimals
                        )?.toFixed(3)}
                  </Box>
                </HStack>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box color="gray.500" fontSize="16px">
                  {" "}
                  Receive {swapData?.action?.toToken?.symbol}
                </Box>

                <HStack>
                  <Avatar
                    name={swapData?.action?.toToken?.symbol}
                    src={swapData?.action?.toToken?.logoURI}
                    borderRadius="300px"
                    boxSize="18px"
                  />
                  <Box>
                    {isNaN(
                      Number(
                        swapData?.estimate?.toAmount /
                          10 ** swapData?.action?.toToken?.decimals
                      )?.toFixed(3)
                    )
                      ? "0"
                      : Number(
                          swapData?.estimate?.toAmount /
                            10 ** swapData?.action?.toToken?.decimals
                        )?.toFixed(3)}
                  </Box>
                </HStack>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box color="gray.500" fontSize="16px">
                  {" "}
                  Recipient
                </Box>

                <Box>
                  {recipient?.slice(0, 7) + "...." + recipient?.slice(-6)}
                </Box>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box color="gray.500" fontSize="16px">
                  {" "}
                  Gas cost
                </Box>

                <Box>${swapData?.estimate?.gasCosts[0]?.amountUSD}</Box>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box color="gray.500" fontSize="16px">
                  {" "}
                  Tool
                </Box>

                <Box>
                  <HStack>
                    <Box>
                      <Avatar
                        boxSize="20px"
                        borderRadius="300px"
                        src={swapData?.toolDetails?.logoURI || ""}
                        name={swapData?.toolDetails?.name || "MatchTrade"}
                      />
                    </Box>
                    <Box>{swapData?.toolDetails?.name || "MatchTrade"}</Box>
                  </HStack>
                </Box>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box color="gray.500" fontSize="16px">
                  {" "}
                  Slippage
                </Box>

                <Box>{Number(swapData?.action?.slippage) * 100}%</Box>
              </Flex>

              <Flex
                mt="10px"
                w="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box color="gray.500" fontSize="16px">
                  {" "}
                  Execution time
                </Box>

                <Box>{swapData?.estimate?.executionDuration}s</Box>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="space-between">
            <Button
              isLoading={isSwapping}
              borderRadius="12px"
              onClick={() => swap()}
              flex="1"
              bg="black"
              color="white"
              _dark={{ bg: "white", color: "black" }}
              _hover={{
                bg: "black",
                color: "white",
                _dark: { bg: "white", color: "black" },
              }}
            >
              Swap
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button
        h="50px"
        isDisabled={swapData == "" ? true : false}
        mt="10px"
        w={{ base: "350px", md: "400px" }}
        borderRadius="12px"
        onClick={onOpen}
      >
        <Flex
          w={{ base: "300px", md: "350px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>Swap</Box>
          <Box
            _hover={{ transform: "translateX(5px)" }}
            transition="transform 0.2s ease-in-out"
          >
            <IoChevronForward />
          </Box>
        </Flex>
      </Button>
    </div>
  );
};

export default SwapInfo;
