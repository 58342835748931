import React from "react";
import {
  Flex,
  Divider,
  Box,
  Image,
  HStack,
  Center,
  Grid,
  GridItem,
  Link,
} from "@chakra-ui/react";
import logo from "../images/matchtrade.svg";

const Footer = () => {
  const data = [
    { title: "Product", extras: [{ text: "App", link: "/app/portfolio" }] },
    {
      title: "Resources",
      extras: [
        {
          text: "Docs",
          link: "https://matchtrades-organization.gitbook.io/matchtrade/",
        },
        {
          text: "$MATCH",
          link: "https://etherscan.io/address/0xEB9E3C6CDa17c8755a4E8a1cc944afb7fc7D7905",
        },
      ],
    },
    {
      title: "Socials",
      move: true,
      extras: [
        { text: "Twitter", link: "https://twitter.com/usematchtrade" },
        { text: "Telegram", link: "https://t.me/matchtradeverification" },
      ],
    },
    {
      title: "Legal",
      move: true,
      extras: [
        { text: "Privacy Policy", link: "#" },
        { text: "Terms of Service", link: "#" },
        { text: "Mandatory Disclaimer", link: "#" },
      ],
    },
  ];
  return (
    <div>
      <Center>
        <Divider
          w={{ base: "90%", md: "90%" }}
          mt={{ base: "200px", md: "300px" }}
          mb="20px"
        />
      </Center>

      <Flex
        w="100%"
        p="50px"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box w={{ base: "50%", md: "40%" }}>
          <HStack>
            <Image boxSize="30px" src={logo} />
            <Box fontSize="20px">MatchTrade</Box>
          </HStack>
        </Box>
        <Box w={{ base: "50%", md: "60%" }} mt={{ base: "40px", md: "0px" }}>
          <Grid
            gap={{ base: "130px", md: "30px" }}
            templateRows={{ base: "repeat(2,1fr)", md: "repeat(1, 1fr)" }}
            templateColumns={{ base: "repeat(2,1fr)", md: "repeat(4,1fr)" }}
          >
            {data?.map((e, i) => {
              return (
                <>
                  <GridItem>
                    <Flex
                      mt={{ base: e?.move ? "-80px" : "0px", md: "0px" }}
                      fontSize="14px"
                      alignItems="flex-start"
                      justifyContent="center"
                      flexDir="column"
                    >
                      <Box>{e?.title}</Box>
                      {e?.extras?.map((e, i) => {
                        return (
                          <>
                            <Link
                              _hover={{ textDecoration: "none" }}
                              href={e?.link}
                            >
                              <Box
                                _hover={{ color: "#00a3ff" }}
                                color="gray.500"
                                mt="30px"
                              >
                                {e?.text}
                              </Box>
                            </Link>
                          </>
                        );
                      })}
                    </Flex>
                  </GridItem>
                </>
              );
            })}
          </Grid>
        </Box>
      </Flex>
      <Center>
        <Divider w={{ base: "90%", md: "90%" }} mt="20px" mb="30px" />
      </Center>

      <Flex
        p="50px"
        fontSize="14px"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        color="gray.500"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box>© 2023 Match Group. All rights reserved.</Box>
        {/*   <Box mt={{ base: "20px", md: "0px" }}>
          <HStack>
            <Flex
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box>
                <HStack>
                  <Image boxSize="20px" src={logo} />
                  <Box color="white">DegenInsure</Box>
                </HStack>
              </Box>
              <Box>Comprehensive insurance for crypto degens.</Box>
            </Flex>
          </HStack>
        </Box> */}
      </Flex>
    </div>
  );
};

export default Footer;
