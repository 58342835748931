import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
/* import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet"; */
import { base, polygonMumbai } from "wagmi/chains";
import { SafeConnector } from "wagmi/connectors/safe";
import { LedgerConnector } from "wagmi/connectors/ledger";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, base, polygonMumbai],
  [publicProvider()]
);
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new LedgerConnector({
      chains: [mainnet],
      projectId: "84b943798e9eac07175e565cec6f6956",
    }),
    /*   new SafeConnector({
      chains,
      options: {
        allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
        debug: false,
      },
    }), */
    /*   new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }), */
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "84b943798e9eac07175e565cec6f6956",
        metadata: {
          name: "MatchTrade",
          description: "Stupid simple trading.",
          url: "https://matchtrade.org",
          icons: ["https://wagmi.sh/icon.png"],
        },
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <WagmiConfig config={config}>
        <BrowserRouter>
          {" "}
          <App />
        </BrowserRouter>
      </WagmiConfig>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
