import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  HStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  PinInput,
  PinInputField,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  useToast,
  Grid,
  GridItem,
  Image,
  Divider,
  Badge,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import axios from "axios";
import intro from "../images/matchtradeintro.mp4";
import moment from "moment";
import { CiClock2 } from "react-icons/ci";
import { IoPerson } from "react-icons/io5";
import { TbMoneybag } from "react-icons/tb";
import { FaXTwitter } from "react-icons/fa6";

const VolumeComp = () => {
  const currentTimestamp = moment().unix();
  const [deets, setDeets] = useState({});
  const toast = useToast();
  const { address } = useAccount();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDetailsOpen,
    onOpen: onDetailsOpen,
    onClose: onDetailsClose,
  } = useDisclosure();
  const {
    isOpen: isAcceptedOpen,
    onOpen: onAcceptedOpen,
    onClose: onAcceptedClose,
  } = useDisclosure();
  const [amount, setAmount] = useState("0");
  const [ethBalance, setEthBalance] = useState("0");
  const [field1, setField1] = useState(0);
  const [field2, setField2] = useState(0);
  const [displayName, setDisplayName] = useState("days");
  const [unixTime, setUnixTime] = useState("0");
  const [isCreating, setIsCreating] = useState(false);
  const [comps, setComps] = useState([]);
  const [sortedComps, setSortedComps] = useState([]);
  const [isAccepting, setIsAccepting] = useState(false);
  const shareOnTwitter = () => {
    window.open(twitterIntentURL, "_blank", "width=600,height=300");
  };

  const message = `I just accepted a challenge to potentially win ${deets?.amount} ETH on @usematchtrade! Challenge anyone yourself, or accept a challenge from anyone, and win 95% of the total wager amount! Get started at matchtrade.org!`;
  const twitterIntentURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    message
  )}`;

  /*   const ca = "0x8d2Ff13de8C05605d7dd9998c224E129865d021A"; */
  const ca = "0xD546aE409025Ad6405a8E28e6C96375C7A37697C";
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [abi, setAbi] = useState([]);
  const [contract, setContract] = useState("");
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await axios
          .get("https://serverrrrr-0tkv.onrender.com/api/getABI", {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          })
          .then((data) => setAbi(data.data.abi));
      } catch (e) {}
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    const newContract = new ethers.Contract(ca, abi, signer);
    setContract(newContract);
  }, [abi]);

  useEffect(() => {
    const fetchEthBalance = async () => {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://base.llamarpc.com"
      );
      const balance = await provider.getBalance(address);
      setEthBalance(ethers.utils.formatEther(balance));
    };

    fetchEthBalance();
  }, []);

  function generateTimestamp(number, unit) {
    const now = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds

    let timeInSeconds;
    switch (unit) {
      case "hours":
        timeInSeconds = number * 3600; // Number of hours in seconds
        break;
      case "days":
        timeInSeconds = number * 86400; // Number of days in seconds
        break;
      case "minutes":
        timeInSeconds = number * 60; // Number of minutes in seconds
        break;
      default:
        timeInSeconds = 0; // Default to 0 if the unit is not recognized
    }

    const futureTimestamp = now + timeInSeconds; // Calculate the future Unix timestamp
    return futureTimestamp;
  }

  useEffect(() => {
    const timee = generateTimestamp(field1 + field2, displayName);
    setUnixTime(timee);
  }, [field1, field2, displayName]);

  const createCompetition = async () => {
    setIsCreating(true);
    const amount2 = ethers.utils.parseEther(amount.toString());
    const end = parseInt(unixTime);

    try {
      const transaction = await contract.createIndividualChallenge(end, 0, {
        value: amount2,
      });
      await transaction.wait();
      setIsCreating(false);
      toast({
        title: "Competition created!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      setIsCreating(false);

      toast({
        title: "Error in creating competition!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const fetchAllChallenges = async () => {
    setInterval(async () => {
      try {
        const provider2 = new ethers.providers.JsonRpcProvider(
          "https://base.llamarpc.com"
        );
        const contracte = new ethers.Contract(ca, abi, provider2);
        const challenges = await contracte.getCompetitionsByType(0);
        setComps(challenges);
      } catch (e) {}
    }, 5000);
  };

  useEffect(() => {
    fetchAllChallenges();
  }, [contract]);

  useEffect(() => {
    if (comps) {
      try {
        const competitionObjects = comps.map((competition) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: competition.endTime,
          challengeAmount: competition.challengeAmount,
          creatorVolume: competition.creatorVolume,
          challengerVolume: competition.challengerVolume,
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: competition.competitionId,
        }));
        setSortedComps(competitionObjects);
      } catch (e) {}
    }
  }, [comps]);

  function getTimeFromTimestamp(unixTimestamp) {
    const currentTime = moment();
    const timestamp = moment.unix(unixTimestamp);

    if (timestamp.isAfter(currentTime)) {
      const duration = moment.duration(timestamp.diff(currentTime));
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes() % 60);
      if (hours > 0) {
        return `about ${hours} ${hours > 1 ? "hours" : "hour"} `;
      } else {
        return `about ${minutes} ${
          minutes > 1 || minutes == 0 ? "minutes" : "minute"
        }`;
      }
    } else {
      return "ended";
    }
  }

  function formatTimestamp(unixTimestamp) {
    const time = moment.unix(unixTimestamp);
    const formattedTime = time.format("ddd Do, HH:mm a");
    return formattedTime;
  }

  const acceptChallenge = async (_competitionId) => {
    try {
      setIsAccepting(true);
      const transaction = await contract.acceptIndividualChallenge(
        _competitionId,
        {
          value: String(deets?.actualAmount), // Replace with the amount in ether
        }
      );
      await transaction.wait();

      toast({
        title: "Challenge accepted!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsAccepting(false);
      onDetailsClose();
      onAcceptedOpen();
    } catch (error) {
      setIsAccepting(false);
      alert(error?.error?.message);

      toast({
        title: "Failed to accept!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  setInterval(async () => {
    try {
      for (const competition of sortedComps) {
        await axios.post(
          "https://serverrrrr-0tkv.onrender.com/api/matches/addMatch",
          {
            competitionId: String(competition.index),
            creator: String(competition.creator),
            challenger: String(competition.challenger),
            endTime: String(competition.endTime),
            challengeAmount: String(competition.challengeAmount),
            compState: String(competition.state),
            compType: String(competition.competitionType),
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
      }
    } catch (error) {}
  }, 60000);

  return (
    <div>
      <Flex
        flexDir="column"
        w="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex w="80%" alignItems="center" justifyContent="flex-end">
          <Button
            leftIcon={<AiOutlinePlus />}
            onClick={onOpen}
            borderRadius="10px"
          >
            Challenge anyone
          </Button>
        </Flex>
        {comps?.length == 0 ? (
          <>
            <Box>
              <Flex
                w="100%"
                mt="50px"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
              >
                <Image src="https://investdex.io/static/media/lock_Icon.2406b9f1.svg" />
                <Box mt="30px" color="gray.500">
                  No challenges available at the moment!
                </Box>
              </Flex>
            </Box>
          </>
        ) : (
          <>
            {" "}
            <Grid
              mt="40px"
              templateRows={{
                base: "repeat(3,1fr)",
                md: "repeat(3,1fr)",
                lg: "repeat(1,1fr)",
              }}
              templateColumns={{
                base: "repeat(1,1fr)",
                md: "repeat(2,1fr)",
                lg: "repeat(4,1fr)",
              }}
              gap={10}
            >
              {sortedComps?.map((e, i) => {
                return (
                  <>
                    <GridItem
                      onClick={() => {
                        setDeets({
                          creator:
                            e?.creator?.slice(0, 7) +
                            "...." +
                            e?.creator?.slice(-6),
                          state: e?.state,
                          endTime: String(e?.endTime),
                          amount: ethers.utils.formatEther(
                            String(e?.challengeAmount)
                          ),
                          actualAmount: e?.challengeAmount,
                          index: e?.index,
                        });
                        onDetailsOpen();
                      }}
                      _hover={{ cursor: "pointer" }}
                      p="10px"
                      bg="#f5f5f5"
                      _dark={{ bg: "#15161a" }} //#15161a #242424
                      w="300px"
                      h="400px"
                      borderRadius="30px"
                    >
                      <div
                        style={{
                          borderRadius: "20px",
                          overflow: "hidden",
                        }}
                      >
                        <video width="100%" height="100px" autoPlay muted loop>
                          <source
                            src={"https://i.imgur.com/Lk99xdJ.mp4"}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                      <Badge colorScheme="teal" mt="-550px" ml="200px">
                        {String(e?.claimed) == "true" ? "FINISHED" : ""}
                      </Badge>
                      <HStack mt="-10px" fontSize="12px">
                        <Image
                          borderRadius="10px"
                          border="5px solid #f5f5f7"
                          _dark={{ border: "5px solid #15161a" }}
                          src={`https://www.gravatar.com/avatar/${e?.creator}?d=identicon`}
                          boxSize={{ base: "40px", md: "40px" }}
                        />
                        <Flex
                          flexDir="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <HStack>
                            <Box color="gray.500">Challenger:</Box>
                            <Box>
                              {e?.creator?.slice(0, 7) +
                                "...." +
                                e?.creator?.slice(-6)}
                            </Box>
                          </HStack>

                          <HStack>
                            <Box color="gray.500">Ends in:</Box>
                            <Box>
                              {getTimeFromTimestamp(String(e?.endTime))}
                            </Box>
                          </HStack>
                        </Flex>
                      </HStack>
                      <Box mt="5px" fontSize="20px">
                        {ethers.utils.formatEther(String(e?.challengeAmount))}
                        ETH
                      </Box>
                    </GridItem>
                  </>
                );
              })}
            </Grid>
          </>
        )}
      </Flex>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="15px">
          <ModalHeader>Challenge anyone</ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <Alert borderRadius="10px" mb="10px" status="warning">
              <AlertIcon />
              <AlertTitle>
                Competition durations max out at 2 weeks, or 14 days.
              </AlertTitle>
            </Alert>
            <Input
              _dark={{ bg: "#2b2b2b" }}
              bg="#f5f5f7"
              border="none"
              borderRadius="10px"
              placeholder="Enter the challenge amount in ETH"
              onChange={(e) => setAmount(e?.target?.value)}
            />
            <Box color="gray.500" mt="5px" fontSize="13px">
              You have{" "}
              <Box color="#00a3ff" as="span" display="inline-block">
                {" "}
                {Number(ethBalance)?.toFixed(3)}
              </Box>{" "}
              ETH
            </Box>

            <Box mt="20px">How long should the competition last?</Box>

            <HStack mt="10px">
              <PinInput placeholder="0">
                <PinInputField
                  onChange={(e) => setField1(e.target.value)}
                  borderRadius="10px"
                />
                <PinInputField
                  onChange={(e) => setField2(e.target.value)}
                  borderRadius="10px"
                />
              </PinInput>

              <Popover>
                <PopoverTrigger>
                  <Button borderRadius="10px">{displayName}</Button>
                </PopoverTrigger>
                <PopoverContent
                  _dark={{ bg: "#15161a" }}
                  bg="#f9f9f9"
                  w="180px"
                  p="10px"
                  borderRadius="15px"
                >
                  <PopoverBody>
                    <VStack>
                      <Button
                        onClick={() => setDisplayName("minutes")}
                        borderRadius="10px"
                        w="150px"
                      >
                        minutes
                      </Button>
                      <Button
                        onClick={() => setDisplayName("hours")}
                        borderRadius="10px"
                        w="150px"
                      >
                        hours
                      </Button>
                      <Button
                        onClick={() => setDisplayName("days")}
                        borderRadius="10px"
                        w="150px"
                      >
                        days
                      </Button>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>

            <Button
              isLoading={isCreating}
              onClick={() => createCompetition()}
              mt="20px"
              borderRadius="10px"
              w="100%"
            >
              Create challenge
            </Button>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isDetailsOpen}
        onClose={onDetailsClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="15px">
          <ModalHeader>Accept challenge</ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <Alert borderRadius="10px" mb="10px" status="warning">
              <AlertIcon />
              <AlertTitle>
                Accepting this competition will cost you {deets?.amount} ETH.
              </AlertTitle>
            </Alert>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">Creator</Box>
              <Box>{deets?.creator}</Box>
            </Flex>
            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">Comp stake</Box>
              <Box>{deets?.amount} ETH</Box>
            </Flex>
            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">End time and date</Box>
              <Box>{formatTimestamp(deets?.endTime)}</Box>
            </Flex>

            <Button
              isLoading={isAccepting}
              onClick={() => acceptChallenge(deets?.index)}
              isDisabled={
                deets?.endTime < currentTimestamp &&
                String(deets?.challenger) !==
                  "0x0000000000000000000000000000000000000000"
                  ? true
                  : false
              }
              mt="20px"
              w="100%"
              borderRadius="10px"
            >
              {deets?.endTime < currentTimestamp
                ? "Expired"
                : "Accept challenge"}
            </Button>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isAcceptedOpen}
        onClose={onAcceptedClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="15px">
          <ModalHeader>Challenge Accepted!</ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <HStack
              mt="10px"
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <HStack>
                <Box color="gray.500">
                  {" "}
                  <CiClock2 />
                </Box>

                <Box color="gray.500" _dark={{ color: "#7f7f7f" }}>
                  Ends:
                </Box>
              </HStack>

              <Box>{formatTimestamp(String(deets?.endTime))}</Box>
            </HStack>

            <HStack
              mt="10px"
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <HStack>
                <Box color="gray.500">
                  {" "}
                  <IoPerson />
                </Box>

                <Box color="gray.500" _dark={{ color: "#7f7f7f" }}>
                  Creator:
                </Box>
              </HStack>

              <Box>{deets?.creator}</Box>
            </HStack>

            <HStack
              mt="10px"
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <HStack>
                <Box color="gray.500">
                  {" "}
                  <TbMoneybag />
                </Box>

                <Box color="gray.500" _dark={{ color: "#7f7f7f" }}>
                  Wager:
                </Box>
              </HStack>

              <Box>{deets?.amount} ETH</Box>
            </HStack>

            <Link onClick={shareOnTwitter} cursor="pointer">
              <Button mt="20px" w="100%" borderRadius="10px">
                Share on{" "}
                <Box as="span" ml="5px" display="inline-block">
                  <FaXTwitter />
                </Box>
              </Button>
            </Link>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default VolumeComp;
