import React, { useEffect, useState } from "react";
import { Box, Text, Progress, Avatar, Flex } from "@chakra-ui/react";
import { HiArrowLongRight } from "react-icons/hi2";

const Countdown = ({ durationInSeconds, fromTokenImage, toTokenImage }) => {
  const [timeLeft, setTimeLeft] = useState(durationInSeconds);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  const percentage = ((durationInSeconds - timeLeft) / durationInSeconds) * 100;

  const changeTitle = () => {
    document.title = ` ${timeLeft}s remaining`;
  };

  useEffect(() => {
    if (durationInSeconds) {
      changeTitle();
    }
  }, [timeLeft]);

  return (
    <Box>
      <Text mb="300px" fontSize={{ base: "40px", md: "60px" }}>
        {/* {Math.floor(timeLeft / 3600)}h */}{" "}
        {Math.floor((timeLeft % 3600) / 60)}m {timeLeft % 60}s
        <Flex
          mt="300px"
          mb="-300px"
          alignItems="center"
          justifyContent="center"
          w="100%"
        >
          <Avatar mr="20px" src={fromTokenImage} boxSize="30px" />
          <Progress
            w={{ base: "100%", md: "80%" }}
            borderRadius="5px"
            h="3px"
            value={percentage}
            colorScheme="blue"
            marginTop={2}
          />
          {/*   <HiArrowLongRight /> */}
          <Avatar boxSize="30px" ml="20px" src={toTokenImage} />
        </Flex>
      </Text>
    </Box>
  );
};

export default Countdown;
