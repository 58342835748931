import React, { useState, useEffect } from "react";
import AppHeader from "../components/AppHeader";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { Client } from "@covalenthq/client-sdk";
import {
  Box,
  Flex,
  Avatar,
  Image,
  Input,
  HStack,
  Kbd,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useColorMode,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa6";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const Portfolio = () => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [fetchedId, setFetchedId] = useState("");
  const [chainId, setChainId] = useState(1);
  const [chainName, setChainName] = useState("Ethereum");
  const [chainIdentifier, setChainIdentifier] = useState("eth-mainnet");
  const [serverName, setServerName] = useState("eth");
  const [chainLogo, setChainLogo] = useState(
    "https://app.openocean.finance/img/eth.a33278df.svg"
  );
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const { address } = useAccount();
  const [netWorth, setNetWorth] = useState(0);
  function formatBalanceWithSuffix(balance, contractDecimals) {
    const num = ethers.utils.formatUnits(balance, contractDecimals);
    const numValue = parseFloat(num);

    if (numValue >= 1e6) {
      return (numValue / 1e6).toFixed(2) + "M";
    } else if (numValue >= 1e3) {
      return (numValue / 1e3).toFixed(2) + "K";
    } else {
      return numValue.toFixed(2);
    }
  }

  function formatBalanceWithSuffix2(balance) {
    if (balance >= 1e6) {
      return (balance / 1e6)?.toFixed(2) + "M";
    } else if (balance >= 1e3) {
      return (balance / 1e3)?.toFixed(2) + "K";
    } else {
      return balance?.toFixed(2); // No suffix for values less than 1,000
    }
  }

  const ApiServices = async () => {
    const client = new Client(process.env.REACT_APP_COVALENT_API_KEY);
    const resp = await client.BalanceService.getTokenBalancesForWalletAddress(
      chainIdentifier,
      address,
      { noSpam: true }
    );

    setData(resp?.data?.items);
  };
  useEffect(() => {
    ApiServices();
  }, [address, chainIdentifier, chainId]);

  useEffect(() => {
    if (data) {
      const sumOfQuotes = data.reduce((total, obj) => {
        if (obj.hasOwnProperty("quote")) {
          return total + obj.quote;
        }

        return total;
      }, 0);
      setNetWorth(formatBalanceWithSuffix2(sumOfQuotes));
    }
  }, [data]);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = data.filter((item) => {
      return (
        item?.contract_name?.toLowerCase()?.includes(query?.toLowerCase()) ||
        item?.contract_ticker_symbol
          ?.toLowerCase()
          ?.includes(query?.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  const handleKeyPress = (event) => {
    if (event.key === "f") {
      document.getElementById("searchInput").focus();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const networks = [
    {
      name: "Ethereum",
      chainId: 1,
      logo: "https://app.openocean.finance/img/eth.a33278df.svg",
      chainName: "eth-mainnet",
      serverName: "eth",
    },
    {
      name: "Base",
      chainId: 8453,
      logo: "https://app.openocean.finance/img/base.fa1f5b27.svg",
      chainName: "base-mainnet",
      serverName: "base",
    },
    {
      name: "Polygon",
      chainId: 137,
      logo: "https://cdn.via.exchange/tokens/Matic.svg",
      chainName: "matic-mainnet",
      serverName: "polygon",
    },
    {
      name: "BSC",
      chainId: 56,
      logo: "https://cdn.via.exchange/tokens/BNB.svg",
      chainName: "bsc-mainnet",
      serverName: "bsc",
    },
  ];

  const getCurrentChainId = async () => {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        const chainId = network.chainId;
        return chainId;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  setInterval(() => {
    getCurrentChainId().then((chainIds) => {
      if (chainId !== null) {
        setChainId(chainIds);
        setFetchedId(chainIds);
      } else {
      }
    });
  }, 5000);

  const switchToChainId = async (targetChainId) => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${targetChainId.toString(16)}` }],
        });
      } else {
      }
    } catch (error) {}
  };

  const handleCopyToClipboard = () => {
    const formattedAddress = address?.toLowerCase()?.startsWith("0x")
      ? address?.toLowerCase()
      : `0x${address?.toLowerCase()}`;

    // Copy the formatted address to the clipboard
    navigator.clipboard
      .writeText(formattedAddress)
      .then(() => {
        toast({
          description: "Address copied",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {});
  };

  return (
    <div>
      <AppHeader />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Flex
          p="20px"
          w={{ base: "100%", md: "50%" }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Image
            borderRadius="10px"
            border="5px solid #f5f5f7"
            _dark={{ border: "5px solid #15161a" }}
            src={`https://www.gravatar.com/avatar/${address}?d=identicon`}
            boxSize={{ base: "70px", md: "100px" }}
          />
          <Flex
            flexDir="column"
            w="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box
              ml="15px"
              fontSize={{ base: "30px", md: "46px" }}
              fontWeight="700"
            >
              ${netWorth}
            </Box>
            <HStack>
              <Box color="gray.500" ml="15px" fontWeight="600">
                {" "}
                {address?.slice(0, 8) + "...." + address?.slice(-6)}
              </Box>
              <Box
                onClick={() => handleCopyToClipboard()}
                color="gray.500"
                _hover={{ cursor: "pointer" }}
              >
                <FaRegCopy />
              </Box>
            </HStack>
          </Flex>
        </Flex>
      </Flex>

      <Flex w="100%" alignItems="center" justifyContent="center">
        <Flex
          w={{ base: "90%", md: "47%" }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <HStack>
            <Box>
              <Input
                borderRadius="10px"
                id="searchInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
                placeholder="Search your tokens"
                _dark={{ bg: "#15161a" }}
                bg="#f5f5f7"
                border="none"
              />
            </Box>
            <Box>
              <Kbd>F</Kbd>
            </Box>
          </HStack>
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        w="100%"
        alignItems="center"
        justifyContent="center"
        p={{ base: "15px", md: "30px" }}
        mb={{ base: "-20px", md: "-40px" }}
      >
        <Flex
          w={{ base: "90%", md: "47%" }}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Box>
            <Popover>
              <PopoverTrigger>
                <Button borderRadius="10px">
                  <HStack>
                    <Avatar
                      src={chainLogo}
                      name={chainName}
                      boxSize="20px"
                      borderRadius="400px"
                      fontSize="5px"
                      fontWeight="600"
                      loading="lazy"
                    />
                    <Box>{chainName}</Box>
                  </HStack>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                overflowY="scroll"
                borderRadius="10px"
                _dark={{ bg: "#15161a" }}
                bg="#f9f9f9"
                w="250px"
              >
                <PopoverBody>
                  {networks?.map((e, i) => {
                    return (
                      <>
                        <Flex
                          onClick={() => {
                            switchToChainId(e?.chainId);
                            setChainId(e?.chainId);
                            setChainName(e?.name);
                            setChainLogo(e?.logo);
                            setChainIdentifier(e?.chainName);
                            setServerName(e?.serverName);
                          }}
                          w="100%"
                          alignItems="center"
                          justifyContent="space-between"
                          p="10px"
                          _hover={{
                            bg: colorMode == "dark" ? "#2B2B2B" : "#E9E9E9",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <HStack>
                            <Avatar
                              src={e?.logo}
                              name={e?.name}
                              boxSize="30px"
                              borderRadius="300px"
                            />
                            <Box>{e?.name}</Box>
                          </HStack>
                          <Box
                            display={
                              String(fetchedId) === String(e?.chainId)
                                ? "flex"
                                : "none"
                            }
                            color="blue.500"
                          >
                            <BsDot />
                          </Box>
                        </Flex>
                      </>
                    );
                  })}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        w="100%"
        alignItems="center"
        justifyContent="center"
        p={{ base: "15px", md: "30px" }}
      >
        {/*  {filteredData?.map((e, i) => {
          return (
            <>
              <Box
                _hover={{ cursor: "pointer" }}
                borderRadius="15px"
                p={{ base: "20px", md: "10px" }}
                mt="10px"
                w={{ base: "100%", md: "50%" }}
                bg="#f5f5f7"
                _dark={{ bg: "#15161a" }}
              >
                <Link
                  state={{ data: data[i], data2: data, data3: serverName }}
                  _hover={{ textDecoration: "none" }}
                  to={`/app/trade/step1?tokenIn=${e?.contract_address}`}
                >
                  <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {" "}
                    <Avatar
                      src={e?.logo_url}
                      boxSize={{ base: "35px", md: "35px" }}
                      borderRadius="400px"
                      name={e?.contract_name}
                    />
                    <Box display={{ base: "yes", md: "none" }}>
                      <Flex
                        flexDir="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                      >
                        <Box fontWeight="700">{e?.contract_name}</Box>
                        <Box color="gray.500">
                          {formatBalanceWithSuffix(
                            e?.balance,
                            e?.contract_decimals
                          )}{" "}
                          {e?.contract_ticker_symbol}
                        </Box>
                      </Flex>
                    </Box>
                    <Box display={{ base: "yes", md: "none" }}>
                      <Flex
                        flexDir="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                      >
                        <Box>${formatBalanceWithSuffix2(e?.quote) || 0}</Box>
                        <Box>
                          <Box
                            color={
                              e?.quote - e?.quote_24h > 0 ? "#22f8ab" : "red"
                            }
                          >
                            {e?.quote_24h !== 0 ? (
                              <Box
                                color={
                                  e?.quote - e?.quote_24h > 0 ? "green" : "red"
                                }
                              >
                                {(
                                  ((e?.quote - e?.quote_24h) / e?.quote_24h) *
                                  100
                                ).toFixed(2)}
                                %
                              </Box>
                            ) : (
                              <Box color="gray">0%</Box>
                            )}
                          </Box>
                        </Box>
                      </Flex>
                    </Box>{" "}
                    <Box
                      display={{ base: "none", md: "flex" }}
                      fontWeight="700"
                    >
                      {e?.contract_name}
                    </Box>
                    <Box
                      display={{ base: "none", md: "flex" }}
                      color="gray.500"
                    >
                      <Box>
                        {" "}
                        {formatBalanceWithSuffix(
                          e?.balance,
                          e?.contract_decimals
                        )}{" "}
                        {e?.contract_ticker_symbol}
                      </Box>
                    </Box>
                    <Box display={{ base: "none", md: "flex" }}>
                      ${formatBalanceWithSuffix2(e?.quote) || 0}
                    </Box>
                    <Box
                      display={{ base: "none", md: "flex" }}
                      color={e?.quote - e?.quote_24h > 0 ? "#22f8ab" : "red"}
                    >
                      {e?.quote_24h !== 0 ? (
                        <Box
                          color={e?.quote - e?.quote_24h > 0 ? "green" : "red"}
                        >
                          {(
                            ((e?.quote - e?.quote_24h) / e?.quote_24h) *
                            100
                          ).toFixed(2)}
                          %
                        </Box>
                      ) : (
                        <Box color="gray">0%</Box>
                      )}
                    </Box>
                  </Flex>
                </Link>
              </Box>
            </>
          );
        })} */}

        <Table w={{ base: "90%", md: "50%" }} variant="simple">
          <Thead>
            <Tr>
              <Th>Logo</Th>
              <Th>Symbol</Th>
              <Th>Balance</Th>
              <Th display={{ base: "none", md: "yes" }}>Value</Th>
              <Th>Trade</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((e, i) => (
              <Tr key={i}>
                <Td>
                  <Avatar
                    src={e?.logo_url}
                    boxSize="30px"
                    borderRadius="400px"
                    name={e?.contract_name}
                  />
                </Td>

                <Td display={{ base: "none", md: "table-cell" }}>
                  <Link
                    state={{ data: e, data2: data, data3: serverName }}
                    _hover={{ textDecoration: "none" }}
                    to={`/app/trade/step1?tokenIn=${e?.contract_address}`}
                  >
                    {e?.contract_ticker_symbol}
                  </Link>
                </Td>
                <Td>
                  <Flex
                    display={{ base: "flex", md: "none" }}
                    flexDir="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Box>{e?.contract_ticker_symbol}</Box>
                    <Box color="gray.500">
                      {formatBalanceWithSuffix(
                        e?.balance,
                        e?.contract_decimals
                      )}
                    </Box>
                  </Flex>
                  <Flex
                    flexDir="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    display={{ base: "none", md: "flex" }}
                  >
                    <Box>
                      {formatBalanceWithSuffix(
                        e?.balance,
                        e?.contract_decimals
                      )}{" "}
                      {e?.contract_ticker_symbol}
                    </Box>
                    <Box
                      display={{ base: "none", md: "flex" }}
                      color={
                        e?.quote - e?.quote_24h > 0 ? "#3fb66b" : "#ff5f52"
                      }
                    >
                      {e?.quote_24h !== 0 ? (
                        <Box
                          color={
                            e?.quote - e?.quote_24h > 0 ? "#3fb66b" : "#ff5f52"
                          }
                        >
                          <HStack spacing="0px">
                            <Box>
                              {" "}
                              {(
                                ((e?.quote - e?.quote_24h) / e?.quote_24h) *
                                100
                              ).toFixed(2)}
                              %
                            </Box>
                            <Box>
                              {e?.quote - e?.quote_24h > 0 ? (
                                <TiArrowSortedUp />
                              ) : (
                                <TiArrowSortedDown />
                              )}
                            </Box>
                          </HStack>
                        </Box>
                      ) : (
                        <Box color="gray">0%</Box>
                      )}
                    </Box>
                  </Flex>
                </Td>
                <Td>${formatBalanceWithSuffix2(e?.quote) || 0}</Td>
                <Td>
                  <Box>
                    {" "}
                    <Link
                      key={i}
                      state={{ data: e, data2: data, data3: serverName }}
                      _hover={{ textDecoration: "none" }}
                      to={`/app/trade/step1?tokenIn=${e?.contract_address}`}
                    >
                      <Button size="sm" borderRadius="10px">
                        Trade
                      </Button>
                    </Link>
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
    </div>
  );
};

export default Portfolio;
