import React from "react";
import { IconButton } from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/color-mode";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

const DarkModeButton = ({ size, borderRadius }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <IconButton
        size={size || "sm"}
        borderRadius={borderRadius || "8px"}
        onClick={() => toggleColorMode()}
      >
        {colorMode === "dark" ? <SunIcon /> : <MoonIcon />}
      </IconButton>
    </>
  );
};

export default DarkModeButton;
