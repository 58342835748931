import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useToast,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useConnect } from "wagmi";

const ConnectButton = ({ isDisabledd }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  useEffect(() => {
    if (error) {
      toast({
        description: error?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error]);

  return (
    <>
      <Button
        w="100%"
        borderRadius="10px"
        size={{ base: "md", md: "md" }}
        onClick={onOpen}
        isDisabled={!isDisabledd}
      >
        Connect Wallet
      </Button>

      <Modal
        size={{ base: "full", md: "xs" }}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="20px">
          <ModalHeader>Connect a Wallet</ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            {connectors.map((connector) => (
              <Button
                fontSize={{ base: "11px", md: "13px" }}
                w="100%"
                h="50px"
                isLoading={
                  connector.id === pendingConnector?.id ? isLoading : ""
                }
                mb="10px"
                borderRadius="15px"
                isDisabled={!connector.ready}
                key={connector.id}
                onClick={() => {
                  connect({ connector });
                  onClose();
                }}
              >
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing="10px"
                >
                  <Box>
                    {connector.name}
                    {!connector.ready /* && " (unsupported)" */}
                  </Box>
                  <Box>
                    <Image
                      boxSize="30px"
                      borderRadius="400px"
                      src={
                        connector.name === "MetaMask"
                          ? "https://router.via.exchange/assets/img/metamask-5390e843.svg"
                          : connector.name === "Injected"
                          ? "https://cdn.dribbble.com/users/11041/screenshots/1925820/wallet_icons.png"
                          : connector.name === "Coinbase Wallet"
                          ? "https://router.via.exchange/assets/img/coinbase-c872eebd.svg"
                          : connector.name === "Safe"
                          ? "https://pbs.twimg.com/profile_images/1643941027898613760/gyhYEOCE_400x400.jpg"
                          : connector.name === "WalletConnect"
                          ? "https://router.via.exchange/assets/img/walletconnect-d90cb12e.svg"
                          : connector.name === "Ledger"
                          ? "https://blockspot-io.b-cdn.net/wp-content/uploads/ledger-nano-s-wallet-logo.webp"
                          : "fallback-image-url"
                      }
                    />
                  </Box>
                </Flex>
              </Button>
            ))}
          </ModalBody>

          <ModalFooter alignItems="center" justifyContent="center">
            {/*    <Link
              _hover={{ color: "#22e9a0" }}
              href="https://ethereum.org/en/wallets/find-wallet/"
              isExternal
            >
              <Box
                as="span"
                display="inline-flex"
                alignItems="center" // Center vertically
              >
                Get a wallet
                <Box
                  as="span"
                  display="inline-flex"
                  alignItems="center"
                  ml="3px"
                >
                  <HiOutlineArrowNarrowRight />
                </Box>
              </Box>
            </Link> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConnectButton;
