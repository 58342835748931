import {
  Flex,
  HStack,
  Box,
  Image,
  Link,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import logo from "../images/matchtrade.svg";
import DarkModeButton from "./DarkModeButton";
import { FiMenu } from "react-icons/fi";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";

const AppHeader = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const handleKeyPress = (event) => {
    if (event.shiftKey) {
      const key = event.key.toUpperCase();
      const shortcutLinks = {
        P: "/app/portfolio",
        T: "/app/trade",
        M: "/app/match",
        Y: "/app/yields",
        A: "/app/account",
        S: "/app/settings",
      };

      if (shortcutLinks[key]) {
        window.location.href = shortcutLinks[key];
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const links = [
    { title: "Portfolio", link: "/app/portfolio" },
    /*   { title: "Trade", link: "/app/trade" }, */
    { title: "Match", link: "/app/match" },
    /*     { title: "Yields", link: "/app/yields" }, */
    { title: "Account", link: "/app/account" },
    /*  { title: "Settings", link: "/app/settings" }, */
    { title: "Leaderboard", link: "/app/leaderboard" },
  ];

  useEffect(() => {
    if (address === undefined) {
      navigate("/app/login");
    }
  }, [address]);
  return (
    <div>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        p={{ base: "15px", md: "25px" }}
      >
        <HStack>
          <Box>
            {" "}
            <Image
              p="1px"
              _dark={{ bg: "white", p: "5px" }}
              borderRadius="5px"
              boxSize="25px"
              src={logo}
            />
          </Box>
          <Box fontSize="20px"> MatchTrade</Box>
        </HStack>

        <HStack
          fontWeight="500"
          display={{ base: "none", md: "flex" }}
          spacing={8}
        >
          {links?.map((e, i) => {
            return (
              <>
                <Link
                  href={e?.link}
                  _hover={{ textDecoration: "none", color: "#00a3ff" }}
                >
                  <Box
                    color={
                      window.location.pathname == e?.link
                        ? "#00a3ff"
                        : "gray.500"
                    }
                    fontWeight={
                      window.location.pathname == e?.link ? "600" : "500"
                    }
                  >
                    {e?.title}
                  </Box>
                </Link>
              </>
            );
          })}
          <Box>
            <DarkModeButton size={"xs"} />
          </Box>
        </HStack>

        <HStack display={{ base: "flex", md: "none" }}>
          <IconButton
            size="sm"
            borderRadius="8px"
            onClick={onOpen}
            icon={<FiMenu />}
          />
          <DarkModeButton />
        </HStack>
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent _dark={{ bg: "#111215" }}>
          <DrawerCloseButton />
          <DrawerHeader>
            {" "}
            <Link href="/" _hover={{ textDecoration: "none" }}>
              <HStack mb="20px">
                <Image
                  p="1px"
                  _dark={{ bg: "white", p: "5px" }}
                  borderRadius="5px"
                  boxSize="25px"
                  src={logo}
                />
                <Box fontWeight="500">MatchTrade</Box>
              </HStack>
            </Link>
          </DrawerHeader>

          <DrawerBody>
            <Flex flexDir="column" alignItems="flex-start">
              {links?.map((e, i) => {
                return (
                  <div key={i}>
                    <Link
                      href={e?.link}
                      _hover={{ textDecoration: "none", color: "#00a3ff" }}
                    >
                      <Box
                        mt="10px"
                        color={
                          window.location.pathname == e?.link ? "#00a3ff" : ""
                        }
                        fontWeight={
                          window.location.pathname == e?.link ? "600" : "500"
                        }
                      >
                        {e?.title}
                      </Box>
                    </Link>
                  </div>
                );
              })}
            </Flex>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AppHeader;
