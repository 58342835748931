import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  HStack,
  useToast,
  Image,
  Link,
} from "@chakra-ui/react";
import AppHeader from "./AppHeader";
import intro from "../images/matchtradeintro.mp4";
import { ethers } from "ethers";
import moment from "moment";

function CompDeets() {
  const currentTimestamp = moment().unix();
  const { competitionId } = useParams();
  const toast = useToast();
  const [compType, setCompType] = useState("Volume");
  const [competition, setCompetition] = useState(null);
  const [status, setStatus] = useState("Pending");
  const [color, setColor] = useState("");
  const [isAccepting, setIsAccepting] = useState(false);
  const [foundError, setFoundError] = useState(false);

  /*   const ca = "0xCEfe15b6eC4b53De4F90F39d5f4Cbb2eD47dC378"; */
  /*  const ca = "0x6ac37E0F7fE25ff0526B523Fc17475D8C0dA8FBB"; */
  /*   const ca = "0x009943F3E3Ff66f272e8F40A4b48A2187159F4B1"; */
  /*   const ca = "0x101c1ee8E01cd243A853f4b1EDaf1ccE3F28DFa7"; */
  /*   const ca = "0x8d2Ff13de8C05605d7dd9998c224E129865d021A"; */ //ethereum real
  const ca = "0xD546aE409025Ad6405a8E28e6C96375C7A37697C";//base real
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [abi, setAbi] = useState([]);
  const [contract, setContract] = useState("");
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await axios
          .get("https://serverrrrr-0tkv.onrender.com/api/getABI", {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          })
          .then((data) => setAbi(data.data.abi));
      } catch (e) {}
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    const newContract = new ethers.Contract(ca, abi, signer);
    setContract(newContract);
  }, [abi]);

  const acceptChallenge = async (_competitionId) => {
    try {
      setIsAccepting(true);
      const transaction = await contract.acceptIndividualChallenge(
        competition?.competitionId,
        {
          value: String(competition?.challengeAmount),
        }
      );
      await transaction.wait();

      toast({
        title: "Challenge accepted!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsAccepting(false);
    } catch (error) {
      setIsAccepting(false);

      toast({
        title: "Failed to accept!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        const response = await axios.get(
          `https://serverrrrr-0tkv.onrender.com/api/competitions/${competitionId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        setCompetition(response.data);
      } catch (error) {
        if (String(error?.response?.data) == "Competition not found") {
          setFoundError(true);
        }
      }
    };

    fetchCompetition();
  }, [competitionId]);

  function formatTimestamp(unixTimestamp) {
    const time = moment.unix(unixTimestamp);
    const formattedTime = time.format("ddd Do, HH:mm a");
    return formattedTime;
  }

  useEffect(() => {
    if (String(competition?.compType) == "0") {
      setCompType("Volume");
    } else if (String(competition?.compType) == "1") {
      setCompType("PnL");
    } else if (String(competition?.compType) == "2") {
      setCompType("Number");
    }
  }, [competition]);

  useEffect(() => {
    if (
      String(competition?.compState) == "0" &&
      competition?.endTime < currentTimestamp
    ) {
      setStatus("Awaiting finalization");
      setColor("orange");
    } else if (String(competition?.compState) == "0") {
      setStatus("Pending");
      setColor("#01b2ff");
    } else if (String(competition?.compState) == "1") {
      setStatus("Completed");
      setColor("#00ca48");
    } else if (String(competition?.compState) == "3") {
      setStatus("Cancelled by creator");
      setColor("#ff9601");
    } else if (String(competition?.compState) == "4") {
      setStatus("Cancelled by challenger");
      setColor("#ff9601");
    }
  }, [competition]);

  return (
    <>
      <AppHeader />
      {foundError !== true ? (
        <>
          <Flex
            w="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            p={{ base: "20px", md: "50px" }}
            flexDir="column"
          >
            <Box fontWeight="600" fontSize="20px">
              Competition #{competition?.competitionId}
            </Box>
            <Box color="#00ca48" fontSize={{ base: "30px", md: "40px" }}>
              {competition &&
                ethers.utils.formatEther(
                  String(competition?.challengeAmount)
                )}{" "}
              ETH
            </Box>
            <Box>
              <HStack>
                <Box color="gray.500">Created by</Box>
                <Box>
                  {competition?.creator?.slice(0, 7) +
                    "...." +
                    competition?.creator?.slice(-6)}
                </Box>
              </HStack>
            </Box>
            <Box
              border="5px solid #f5f5f7"
              _dark={{ border: "5px solid #15161a" }}
              borderRadius="20px"
              mt="20px"
              w={{ base: "300px", md: "400px" }}
              h={{ base: "300px", md: "400px" }}
            >
              <div
                style={{
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <video width="100%" height="100px" autoPlay muted loop>
                  <source src={intro} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Box>
            <Box
              bg="#efefef"
              _dark={{ bg: "#15161a" }}
              p="10px"
              borderRadius="10px"
              mt="20px"
            >
              <HStack>
                <Box color="gray.500">Challenger</Box>
                <Box>
                  {competition?.challenger?.slice(0, 7) +
                    "...." +
                    competition?.challenger?.slice(-6)}
                </Box>
              </HStack>
            </Box>
            <Box
              bg="#efefef"
              _dark={{ bg: "#15161a" }}
              p="10px"
              borderRadius="10px"
              mt="20px"
            >
              <HStack>
                <Box color="gray.500">Ends</Box>
                <Box>{formatTimestamp(competition?.endTime)}</Box>
              </HStack>
            </Box>
            <Box
              bg="#efefef"
              _dark={{ bg: "#15161a" }}
              p="10px"
              borderRadius="10px"
              mt="20px"
            >
              <HStack>
                <Box color="gray.500">Type</Box>
                <Box>{compType} competition</Box>
              </HStack>
            </Box>
            <Box
              bg="#efefef"
              _dark={{ bg: "#15161a" }}
              p="10px"
              borderRadius="10px"
              mt="20px"
            >
              <HStack>
                <Box color="gray.500">Status</Box>
                <Box color={color}>{status} </Box>
              </HStack>
            </Box>

            <Button
              onClick={() => acceptChallenge()}
              isLoading={isAccepting}
              borderRadius="10px"
              w={{ base: "300px", md: "400px" }}
              mt="20px"
              isDisabled={
                competition?.endTime < currentTimestamp ||
                String(competition?.challenger) !==
                  "0x0000000000000000000000000000000000000000"
                  ? true
                  : false
              }
            >
              {competition?.endTime < currentTimestamp
                ? "Competition Ended"
                : "Participate"}
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            w="100%"
            mt="50px"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Image src="https://investdex.io/static/media/lock_Icon.2406b9f1.svg" />
            <Box mt="30px" color="gray.500">
              That competition doesn't exist...yet
            </Box>
            <Link href="/app/match" _hover={{ textDecoration: "none" }}>
              <Button mt="20px" borderRadius="10px">
                Create it now
              </Button>
            </Link>
          </Flex>
        </>
      )}
    </>
  );
}

export default CompDeets;
