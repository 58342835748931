import React, { useState, useEffect } from "react";
import ConnectButton from "../components/ConnectButton";
import {
  Center,
  Box,
  Link,
  Flex,
  Image,
  Checkbox,
  HStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Button,
  Progress,
} from "@chakra-ui/react";
import logo from "../images/matchtrade.svg";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import DarkModeButton from "../components/DarkModeButton";
import "../App.css";
import axios from "axios";
import Confetti from "react-confetti";
import { fetchUserByAddress } from "../helpers/getUserData";

const SignUp = () => {
  const [separateCountdown, setSeparateCountdown] = useState(5);
  const [countdown, setCountdown] = useState(100);
  const [resp, setResp] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [userName, setUserName] = useState("");
  const [accountDeets, setAccountDeets] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const { address } = useAccount();

  const navigate = useNavigate();

  useEffect(() => {
    if (address) {
      /*   navigate("/app/account-setup"); */
      onOpen();
    }
  }, [address]);

  const createUser = async () => {
    try {
      setIsRegistering(true);
      const response = await axios.post(
        "https://serverrrrr-0tkv.onrender.com/api/createUser",
        { name: userName, ethAddress: address, matchPoints: "0" },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      setResp(response?.data?.name);
      setIsRegistering(false);
    } catch (e) {
      setIsRegistering(false);
    }
  };

  useEffect(() => {
    if (resp) {
      onClose();
      setTimeout(() => {
        navigate("/app/portfolio");
      }, 5000);
      onSuccessOpen();
    }
  }, [resp]);

  useEffect(() => {
    if (isSuccessOpen) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 20); // For a 5-second (5000ms) countdown
      }, 1000);

      const separateInterval = setInterval(() => {
        setSeparateCountdown((prevCountdown) => prevCountdown - 1); // For the separate countdown
      }, 1000);

      setTimeout(() => {
        onSuccessClose();
        clearInterval(interval);
        clearInterval(separateInterval);
        // Perform any redirection logic here after the modal closes
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [isSuccessOpen]);

  const checkDeets = async () => {
    const deets = await fetchUserByAddress(address);
    setAccountDeets(deets);
  };

  useEffect(() => {
    if (address) {
      checkDeets();
    }
  }, [address]);

  useEffect(() => {
    if (accountDeets) {
      navigate("/app/portfolio");
    }
  }, [accountDeets]);

  return (
    <>
      <>
        <div /* className="gradient-background" _dark={{ className: "none" }} */
        >
          <div className="center-content">
            {isSuccessOpen && <Confetti />}
            <Flex
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              p="25px"
            >
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <HStack>
                  <Box>
                    {" "}
                    <Image
                      p="1px"
                      _dark={{ bg: "white", p: "5px" }}
                      borderRadius="5px"
                      boxSize="25px"
                      src={logo}
                    />
                  </Box>
                  <Box fontSize="20px"> MatchTrade</Box>
                </HStack>

                <DarkModeButton />
              </Flex>
            </Flex>
            <Flex flexDir={{ base: "column", md: "row" }} w="100%">
              <Box
                /*  display={{ base: "none", md: "yes" }} */
                borderRadius="15px"
                ml="25px"
                className="gradient-background"
                w={{ base: "85%", md: "30%" }}
                h={{ base: "60vh", md: "100vh" }}
              >
                <Center w="100%" color="white" h="100%">
                  <Image color="white" src={logo} boxSize="150px" />
                </Center>
              </Box>
              <Center
                mt={{ base: "-150px", md: "0px" }}
                w="100%"
                color="white"
                h="100vh"
              >
                <Flex
                  direction={{ base: "column", md: "row" }}
                  w={{ base: "80%", md: "40%" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box w={{ base: "100%", md: "50%" }}>
                    <Flex
                      flexDir="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      w="100%"
                    >
                      <Box>
                        <Box
                          color="black"
                          _dark={{ color: "white" }}
                          fontSize={{ base: "27px", md: "32px" }}
                        >
                          Sign up
                        </Box>
                      </Box>

                      <Box
                        fontSize="14px"
                        color="gray.500"
                        _dark={{ color: "white" }}
                      >
                        Start by connecting your wallet to MatchTrade. It's
                        safe, secure and handled on-device.
                      </Box>

                      <Box mt="20px" w="100%">
                        <ConnectButton isDisabledd={isChecked} />
                      </Box>

                      <Box mt="20px" fontSize="12px">
                        <HStack color="black" _dark={{ color: "white" }}>
                          <Checkbox
                            isChecked={isChecked}
                            onChange={handleCheckboxChange}
                            colorScheme="blue"
                            borderRadius="20px"
                          />
                          <Box>
                            I agree to the{" "}
                            <Link href="/tos">
                              <Box
                                color="#00a3ff"
                                display="inline-block"
                                as="span"
                              >
                                {" "}
                                Terms of Service
                              </Box>
                            </Link>{" "}
                            ,{" "}
                            <Link href="/privacy">
                              <Box
                                color="#00a3ff"
                                display="inline-block"
                                as="span"
                              >
                                {" "}
                                Privacy Policy
                              </Box>
                            </Link>{" "}
                            and{" "}
                            <Link href="/disclaimer">
                              <Box
                                color="#00a3ff"
                                display="inline-block"
                                as="span"
                              >
                                {" "}
                                Risk Disclaimer
                              </Box>
                            </Link>
                            .{" "}
                          </Box>
                        </HStack>
                      </Box>

                      <Divider mt=" 20px" />
                      <Flex
                        color="black"
                        _dark={{ color: "white" }}
                        fontSize="15px"
                        mt="20px"
                        w="100%"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>Have an account?</Box>
                        <Link _hover={{ color: "#00a3ff" }} href="/app/login">
                          {" "}
                          <Box>Log in</Box>
                        </Link>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Center>
            </Flex>

            <Modal
              size={{ base: "full", md: "xs" }}
              isOpen={isOpen}
              onClose={onClose}
              isCentered
            >
              <ModalOverlay />
              <ModalContent
                bg="white"
                _dark={{ bg: "black", border: "1.5px solid #181818" }}
                borderRadius="20px"
              >
                <ModalHeader>Address detected.</ModalHeader>
                <ModalCloseButton borderRadius="10px" />
                <ModalBody>
                  You're connected with the address{" "}
                  <Box fontWeight="700" as="span" display="inline-block">
                    {" "}
                    {address?.slice(0, 6) + "..." + address?.slice(-4)}
                  </Box>
                  .
                  <Input
                    mt="20px"
                    placeholder="Enter a username"
                    borderRadius="10px"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <Button
                    isLoading={isRegistering}
                    onClick={() => createUser()}
                    mt="10px"
                    w="100%"
                    borderRadius="10px"
                  >
                    Sign up
                  </Button>
                </ModalBody>

                <ModalFooter
                  alignItems="center"
                  justifyContent="center"
                ></ModalFooter>
              </ModalContent>
            </Modal>

            <Modal
              size={{ base: "full", md: "xs" }}
              isOpen={isSuccessOpen}
              onClose={onSuccessClose}
              isCentered
            >
              <ModalOverlay />
              <ModalContent
                bg="white"
                _dark={{ bg: "black", border: "1.5px solid #181818" }}
                borderRadius="10px"
              >
                <ModalHeader>Signup successful!</ModalHeader>
                <ModalCloseButton borderRadius="10px" />
                <ModalBody>
                  You've just created an account and are ready to experience
                  stupid simple trading! Redirecting you to the main platform in{" "}
                  {separateCountdown} seconds.
                  <Progress
                    mt="20px"
                    borderRadius="5px"
                    h="3px"
                    value={countdown}
                    max={100}
                    width="100%"
                  />
                </ModalBody>
                <ModalFooter
                  alignItems="center"
                  justifyContent="center"
                ></ModalFooter>
              </ModalContent>
            </Modal>

            {/* <Button onClick={() => onSuccessOpen()}>Open</Button> */}
          </div>
        </div>
      </>
    </>
  );
};

export default SignUp;
