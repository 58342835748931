import {
  Box,
  Image,
  Flex,
  useColorMode,
  Center,
  Text,
  HStack,
  Button,
  Stack,
  Link,
} from "@chakra-ui/react";
import imglight from "../images/heroimglight.png";
import imgdark from "../images/heroimgdark.png";
import { useInView } from "framer-motion";
import React, { useRef } from "react";
import intro from "../images/matchtradeintro.mp4";

const Section3 = () => {
  const { colorMode } = useColorMode();
  const ref = useRef(null);
  const isInView = useInView(ref);

  const blurredCircleStyles = {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    height: "100%",
    borderRadius: "50%",
    filter: "blur(60px)",
    zIndex: 1,
    background: "linear-gradient(to top, #00a3ff, #00a3ff)",
  };

  return (
    <div ref={ref}>
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Stack
          p={{ base: "10px", md: "10px" }}
          direction={{ base: "column", md: "row" }}
          spacing="30px"
          border={
            colorMode === "dark" ? "5px solid #111215" : "2px solid #f5f5f5"
          }
          w="70%"
          borderRadius="20px"
          alignItems="center"
        >
          <div
            style={{
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <video width="300px" height="100px" autoPlay muted loop>
              <source
                src={"https://i.imgur.com/Lk99xdJ.mp4"}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <Flex
            w="100%"
            flexDir="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box fontSize={{ base: "35px", md: "35px" }}>
              Challenge someone now.
            </Box>
            <Box mt="10px">
              Take part in peer-to-peer trading competitions. Match. Trade. Win.{" "}
            </Box>
            <Link href="/app/signup" _hover={{ textDecoration: "none" }}>
              <Button
                mt="10px"
                borderRadius="10px"
                display={{ base: "none", md: "flex" }}
              >
                Sign up
              </Button>
            </Link>
            <Link href="/app/signup" _hover={{ textDecoration: "none" }}>
              <Button
                display={{ base: "flex", md: "none" }}
                borderRadius="10px"
                w="100%"
                mt="10px"
              >
                Sign up
              </Button>
            </Link>
          </Flex>
        </Stack>
      </Flex>

      <Center
        style={{
          position: "relative",
          transform: isInView ? "none" : "translateY(200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        mt="60px"
      >
        {/* <Box sx={blurredCircleStyles} /> */}
      </Center>
    </div>
  );
};

export default Section3;
