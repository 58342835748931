import {
  Box,
  Button,
  Flex,
  HStack,
  Avatar,
  Input,
  useColorMode,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Divider,
  VStack,
  Link,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import axios from "axios";
import Countdown from "../components/Countdown";
import TradeInfo from "../components/TradeInfo";
import GasDetails from "../components/GasDetails";
import { BsGear } from "react-icons/bs";
import { contractAbi } from "../utils/contractAbi";
import { fetchUserByAddress } from "../helpers/getUserData";
import UpdateTradeVolumeModal from "../components/UpdateTradeVolumeModal";
import ApproveInfo from "../components/ApproveInfo";
import SwapInfo from "../components/SwapInfo";

const Trade = () => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userEyeDee, setUserEyeDee] = useState("");
  const [showApprovalToast, setShowApprovalToast] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [typeId, setTypeId] = useState("RECOMMENDED");
  const {
    isOpen: isSwapStartedOpen,
    onOpen: onSwapStartedOpen,
    onClose: onSwapStartedClose,
  } = useDisclosure();
  const [approvalTx, setApprovalTx] = useState({});
  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const [swapData, setSwapData] = useState("");
  const { colorMode } = useColorMode();
  const { address } = useAccount();
  const [amount, setAmount] = useState(0);
  const [fromDetails, setFromDetails] = useState({});
  const [toDetails, setToDetails] = useState({});
  const [inToken, setInToken] = useState("");
  const [outToken, setOutToken] = useState("");
  const [userId, setUserId] = useState("");
  const [slippage, setSlippage] = useState(1);
  const location = useLocation();
  const [gasInfo, setGasInfo] = useState("");
  const [txInfo, setTxInfo] = useState("");
  const [swappingData, setSwappingData] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [swapCompleted, setSwapCompleted] = useState(false);
  const [inTokenBalance, setInTokenBalance] = useState(0);
  const [outTokenBalance, setOutTokenBalance] = useState(0);
  const [rpcUrl, setRpcUrl] = useState("https://eth.llamarpc.com");
  const [link, setLink] = useState("");
  const [cId, setCId] = useState(1);
  const [comps, setComps] = useState([]);
  const [comps2, setComps2] = useState([]);
  const [comps3, setComps3] = useState([]);
  const [sortedComps, setSortedComps] = useState([]);
  /*   const ca = "0x8d2Ff13de8C05605d7dd9998c224E129865d021A"; */
  const ca = "0xD546aE409025Ad6405a8E28e6C96375C7A37697C";
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [abi, setAbi] = useState([]);
  const [contract, setContract] = useState("");
  const [taxDetails, setTaxDetails] = useState({});
  const [hideModal, setHideModal] = useState(false);

  useEffect(() => {
    const hideModalValue = localStorage.getItem("hideModal");
    if (hideModalValue) {
      setHideModal(JSON.parse(hideModalValue));
    }
  }, []);

  const {
    isOpen: isSwapCompletedOpen,
    onOpen: onSwapCompletedOpen,
    onClose: onSwapCompletedClose,
  } = useDisclosure();

  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();

  const {
    isOpen: isTaxOpen,
    onOpen: onTaxOpen,
    onClose: onTaxClose,
  } = useDisclosure();

  useEffect(() => {
    // Fetch swap details from the server using data from the URL
    const queryParams = new URLSearchParams(location.search);
    const tokenIn = queryParams.get("tokenIn");
    setInToken(tokenIn);
    const tokenOut = queryParams.get("tokenOut");
    setOutToken(tokenOut);
  }, [location.search]);

  const data = location.state?.data;
  const data2 = location.state?.data2;
  const [recipient, setRecipient] = useState(address);

  useEffect(() => {
    if (data2 == "eth") {
      setCId("1");
      setRpcUrl("https://eth.llamarpc.com");
    } else if (data2 == "base") {
      setCId("8453");
      setRpcUrl("https://base.llamarpc.com");
    } else if (data2 == "bsc") {
      setCId("56");
      setRpcUrl("https://binance.llamarpc.com");
    } else if (data2 == "polygon") {
      setCId("137");
      setRpcUrl("https://polygon.llamarpc.com");
    }
  }, [data2]);

  useEffect(() => {
    const fetchEthersInfo = async () => {
      try {
        if (
          Object?.keys(data?.fromTokenDetails)?.length == 0 ||
          data == undefined ||
          data == null
        ) {
          const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
          const abi = [
            "function symbol() view returns (string)",
            "function name() view returns (string)",
            "function decimals() view returns (uint8)",
          ];
          const tokenContract1 = new ethers.Contract(inToken, abi, provider);
          const tokenContract2 = new ethers.Contract(outToken, abi, provider);
          try {
            // Fetch token details
            const [symbol, name, decimals] = await Promise.all([
              tokenContract1.symbol(),
              tokenContract1.name(),
              tokenContract1.decimals(),
            ]);

            const foundObject = data?.gecko?.find(
              (obj) =>
                String(obj?.address?.toLowerCase()) ===
                String(inToken?.toLowerCase())
            );

            setFromDetails({
              tokenName: name,
              tokenSymbol: symbol,
              tokenDecimals: decimals,
              logo: foundObject?.logoURI,
            });
          } catch (error) {}

          try {
            // Fetch token details
            const [symbol, name, decimals] = await Promise.all([
              tokenContract2.symbol(),
              tokenContract2.name(),
              tokenContract2.decimals(),
            ]);
            const foundObject = data?.gecko?.find(
              (obj) =>
                String(obj?.address?.toLowerCase()) ===
                String(outToken?.toLowerCase())
            );

            setToDetails({
              tokenName: name,
              tokenSymbol: symbol,
              tokenDecimals: decimals,
              logo: foundObject?.logoURI,
            });
          } catch (error) {}
        }
      } catch (e) {}
    };

    fetchEthersInfo();
  }, [data]);

  useEffect(() => {
    const fetchGasInfo = async () => {
      const info = await axios.get(
        `https://www.okx.com/priapi/v1/wallet/gas/multi-gas-price?t=1698730516535&chainIds=${cId},0`
      );

      setGasInfo(info?.data?.data[0]);
    };
    fetchGasInfo();
  }, []);

  const approve = async () => {
    try {
      setIsApproving(true);
      const provider1 = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider1.getSigner();
      const tokenContract = new ethers.Contract(
        inToken,
        ["function approve(address spender, uint256 amount)"],
        signer
      );
      const tx = await tokenContract.approve(
        swapData?.estimate?.approvalAddress,
        ethers.utils.parseUnits(
          String(amount),
          swapData?.action?.fromToken?.decimals
        )
      );
      await tx.wait();
      setIsApproving(false);
      setApprovalTx(tx);
    } catch (e) {
      setIsApproving(false);
    }
  };

  const getSwapData = async () => {
    if (amount == 0) {
    } else if (amount !== 0) {
      try {
        const datas = await axios.get(
          "https://serverrrrr-0tkv.onrender.com/getSwapInfo",
          {
            params: {
              inTokenAddress: inToken,
              outTokenAddress: outToken,
              amount:
                amount *
                  10 ** data?.fromTokenDetails?.info?.contract_decimals ||
                amount * 10 ** fromDetails?.tokenDecimals,
              inDecimals:
                data?.fromTokenDetails?.info?.contract_decimals ||
                fromDetails?.tokenDecimals,
              userAddress: address,
              recipient: recipient,
              slippage: slippage,
              chainId: cId,
              type: typeId,
            },
          }
        );

        setSwapData(datas?.data);
      } catch (e) {
        if (e?.response?.data?.error == "Request failed with status code 404") {
          toast({
            title: "No routes found.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    }
  };
  useEffect(() => {
    getSwapData();
  }, [inToken, outToken, amount, typeId]);

  const paramos = {
    gasLimit: String(swapData?.transactionRequest?.gasLimit),
    gasPrice: String(swapData?.transactionRequest?.gasPrice),
  };

  /*   const [gassyP, setGassyP] = useState(0);

  const getGasPrice = async () => {
    const provider2 = new ethers.providers.JsonRpcProvider(
      "https://eth.llamarpc.com"
    );
    const gasPrice = await provider2.getFeeData();
    setGassyP(gasPrice?.gasPrice);
    console.log(String(gasPrice?.gasPrice));
  };

  useEffect(() => {
    console.log("Im here");
    console.log(paramos);
    console.log(swapData);
    getGasPrice();
  }, [swapData]); */

  const swap = async () => {
    try {
      const params = {
        from: swapData?.transactionRequest?.from,
        to: swapData?.transactionRequest?.to,
        data: swapData?.transactionRequest?.data,
        value: swapData?.transactionRequest?.value,
        gasLimit: swapData?.transactionRequest?.gasLimit,
        gasPrice:
          swapData?.transactionRequest
            ?.gasPrice /* gassyP */ /* ethers.utils.parseUnits(
            String(gasInfo?.tradeGas?.maxCurrencyAmount),
            "gwei"
          ) */,
      };

      setIsSwapping(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const tx = await signer.sendTransaction(params);
      setTxInfo(tx);
      setSwappingData(tx);
      const receipt = await tx.wait();
      if (receipt?.transactionHash) {
        setIsSwapping(false);
        setTimeRemaining(0);
        onSwapStartedOpen();
      }
    } catch (e) {
    } finally {
      setIsSwapping(false);
    }
  };

  const swapTxnCheck = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    try {
      let txn_test = await provider?.getTransaction(swappingData?.hash);
      if (txn_test) {
        if (txn_test.blockNumber) {
          setSwapCompleted(true);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (txInfo !== null) {
      setInterval(() => {
        swapTxnCheck();
      }, 1000);
    }
  }, [txInfo]);

  const txnCheck = async () => {
    const provider1 = new ethers.providers.Web3Provider(window.ethereum);
    try {
      let txn_test = await provider1?.getTransaction(approvalTx?.hash);

      if (txn_test) {
        if (txn_test.blockNumber) {
          setIsApproving(false);
          setShowApprove(false);
          setShowApprovalToast(true);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (showApprovalToast) {
      toast({
        title: "Approved!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [showApprovalToast]);

  useEffect(() => {
    if (approvalTx !== null) {
      const interval = setInterval(() => {
        txnCheck();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [approvalTx]);

  const addTrade = async () => {
    try {
      const response = await axios.post(
        "https://serverrrrr-0tkv.onrender.com/api/addTradetoTradesArray",
        {
          userId: userEyeDee,
          userTrades: {
            fromTokenAddress: inToken,
            fromTokenSymbol:
              fromDetails?.tokenSymbol ||
              data?.fromTokenDetails?.info?.contract_ticker_symbol,
            toTokenAddress: outToken,
            totokenSymbol:
              toDetails?.tokenName || data?.toTokenDetails?.info?.symbol,
            fromTokenAmount: amount,
            toTokenAmount:
              swapData?.estimate?.toAmount /
              10 ** swapData?.action?.toToken?.decimals,
            fromTokenLogo:
              fromDetails?.logo || data?.fromTokenDetails?.info?.logo_url,
            toTokenLogo: toDetails?.logo || data?.toTokenDetails?.info?.logo,
            hash: `${link}${swappingData?.hash}`,
            chain: data2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (swapCompleted === true) {
      addTrade();
      setIsModalOpen(true);
      handleUpdateMatchPoints();
      /*     onSwapCompletedOpen(); */
      onSwapStartedClose();
    }
  }, [swapCompleted]);

  const fetchBalance = async (ca, decimals) => {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum
    ); /* JsonRpcProvider(rpcUrl) */
    try {
      if (ca?.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
        // Fetch Ethereum balance if the address is for Ethereum
        const balance = await provider.getBalance(address);

        return ethers.utils.formatEther(balance);
      } else {
        // Fetch ERC20 token balance for the provided token address
        const tokenContract = new ethers.Contract(ca, contractAbi, provider);
        const tokenBalance = await tokenContract.balanceOf(address);

        return ethers.utils.formatUnits(tokenBalance, decimals);
      }
    } catch (error) {}
  };

  const getBalances = async () => {
    const inBal = await fetchBalance(
      inToken,
      data?.fromTokenDetails?.info?.contract_decimals ||
        fromDetails?.tokenDecimals
    );
    setInTokenBalance(inBal);

    setTimeout(async () => {
      const outBal = await fetchBalance(
        outToken,
        data?.toTokenDetails?.decimals
      );
      setOutTokenBalance(outBal);
    }, 2000);
  };

  useEffect(() => {
    getBalances();
  }, [inToken, outToken]);

  const fetchDatas = async () => {
    const datas = await fetchUserByAddress(address);

    setUserId(datas?._id);
  };

  useEffect(() => {
    fetchDatas();
  }, [address]);

  const handleUpdateMatchPoints = async () => {
    try {
      const response = await axios.put(
        `https://serverrrrr-0tkv.onrender.com/api/users/${userId}/addMatchPoints`,
        { points: Math.ceil(swapData?.estimate?.toAmountUSD || "20") },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    const getDetails = async () => {
      const eyedee = await fetchUserByAddress(address);
      setUserEyeDee(eyedee?._id);
    };
    getDetails();
  }, [address]);

  useEffect(() => {
    if (String(data2) == "base") {
      setLink("https://basescan.org/tx/");
    } else {
      setLink("https://etherscan.io/tx/");
    }
  }, [data2]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onSwapCompletedOpen();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    onSwapCompletedClose();
  };

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await axios
          .get("https://serverrrrr-0tkv.onrender.com/api/getABI", {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          })
          .then((data) => setAbi(data.data.abi));
      } catch (e) {}
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    const newContract = new ethers.Contract(ca, abi, signer);
    setContract(newContract);
  }, [abi]);

  const fetchAllChallenges = async () => {
    const interval = setInterval(async () => {
      try {
        const provider2 = new ethers.providers.JsonRpcProvider(
          "https://eth-mainnet.g.alchemy.com/v2/aNQ92qIHl_o0nmZklF4di1fWdQmI2hde"
        );
        const contracte = new ethers.Contract(ca, abi, provider2);
        const challenges = await contracte.getCompetitionsByType(0);
        const challenges2 = await contracte.getCompetitionsByType(1);
        const challenges3 = await contracte.getCompetitionsByType(2);

        setComps(challenges);
        setComps2(challenges2);
        setComps3(challenges3);
      } catch (e) {}
    }, 5000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    fetchAllChallenges();
  }, [contract]);

  useEffect(() => {
    if (comps) {
      try {
        const competitionObjects = comps.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: String(competition.endTime),
          challengeAmount: String(competition.challengeAmount),
          creatorVolume: String(competition.creatorVolume),
          challengerVolume: String(competition.challengerVolume),
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: String(competition.competitionId),
          compTrades: [],
        }));

        const competitionObjects2 = comps2.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: String(competition.endTime),
          challengeAmount: String(competition.challengeAmount),
          creatorVolume: String(competition.creatorVolume),
          challengerVolume: String(competition.challengerVolume),
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: String(competition.competitionId),
          compTrades: [],
        }));

        const competitionObjects3 = comps3.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: String(competition.endTime),
          challengeAmount: String(competition.challengeAmount),
          creatorVolume: String(competition.creatorVolume),
          challengerVolume: String(competition.challengerVolume),
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: String(competition.competitionId),
          compTrades: [],
        }));

        const newArray = competitionObjects.concat(
          competitionObjects2,
          competitionObjects3
        );

        setSortedComps(newArray);
      } catch (e) {}
    }
  }, [comps]);

  const filteredArray = sortedComps.filter(
    (obj) =>
      String(obj.creator) === String(address) ||
      String(obj.challenger) === String(address)
  );
  useEffect(() => {
    const updateUserCompDetails = async (compDetails) => {
      try {
        const response = await axios.post(
          "https://serverrrrr-0tkv.onrender.com/updateUserCompDetails",
          {
            userId: userEyeDee,
            compDetails: compDetails,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
      } catch (error) {}
    };

    filteredArray.forEach(async (compDetails) => {
      await updateUserCompDetails(compDetails);
    });
  }, [filteredArray, userEyeDee]);

  const getTaxes = async () => {
    try {
      const requestFrom = axios.get(
        "https://serverrrrr-0tkv.onrender.com/api/checkTaxes",
        {
          params: {
            address: data?.fromTokenDetails?.info?.contract_address,
          },
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      const requestTo = axios.get(
        "https://serverrrrr-0tkv.onrender.com/api/checkTaxes",
        {
          params: {
            address: data?.toTokenDetails?.info?.address,
          },
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      const [responseFrom, responseTo] = await Promise.all([
        requestFrom,
        requestTo,
      ]);

      setTaxDetails({
        fromTokenTaxes: responseFrom.data?.simulationResult,
        toTokenTaxes: responseTo.data?.simulationResult,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getTaxes();
  }, [data?.toTokenDetails?.info?.address]);

  useEffect(() => {
    if (Object?.keys(taxDetails)?.length > 0) {
      const fromTokenBuyTax = Number(taxDetails?.fromTokenTaxes?.buyTax);
      const fromTokenSellTax = Number(taxDetails?.fromTokenTaxes?.sellTax);
      const toTokenBuyTax = Number(taxDetails?.toTokenTaxes?.buyTax);
      const toTokenSellTax = Number(taxDetails?.toTokenTaxes?.sellTax);

      let slippage = 1;

      if (
        (fromTokenBuyTax === 0 || fromTokenSellTax === 0) &&
        (toTokenBuyTax === 0 || toTokenSellTax === 0)
      ) {
        slippage = 1;
      } else {
        const fromTokenMaxTax = Math.max(fromTokenBuyTax, fromTokenSellTax);
        const toTokenMaxTax = Math.max(toTokenBuyTax, toTokenSellTax);

        if (fromTokenMaxTax !== 0) {
          slippage = fromTokenMaxTax + 3;
        }

        if (toTokenMaxTax !== 0) {
          slippage = Math.max(slippage, toTokenMaxTax + 3);
        }
      }

      setSlippage(Math.ceil(slippage));
    }
  }, [taxDetails]);

  useEffect(() => {
    if (Number(slippage) > 1 && String(item) !== "true") {
      onTaxOpen();
    }
  }, [slippage]);

  const [item, setItem] = useState(false);

  useEffect(() => {
    setItem(localStorage.getItem("hideModal"));
  }, []);

  const handleCheckboxChange = () => {
    setHideModal(!hideModal);
    localStorage.setItem("hideModal", JSON.stringify(!hideModal));
  };

  return (
    <div>
      <AppHeader />
      {/*   {inToken}
      {outToken} */}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
      >
        <Box
          bg="#f5f5f7"
          _dark={{ bg: "#15161a" }}
          w={{ base: "350px", md: "400px" }}
          pt="10px"
          pb="10px"
          pl="15px"
          pr="15px"
          borderRadius="10px"
        >
          <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
            <Box fontSize="14px" color="gray.500">
              You pay
            </Box>
            <Box
              _hover={{ cursor: "pointer" }}
              onClick={onSettingsOpen}
              mb="10px"
            >
              <BsGear />
            </Box>
          </Flex>

          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Flex
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDir="column"
            >
              <Box>
                <Input
                  /*        onBlur={getSwapData} */
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  fontSize="36px"
                  placeholder="0"
                  focusBorderColor={
                    colorMode == "light" ? "#f5f5f7" : "#15161a"
                  }
                  border="none"
                  p="0px"
                />
              </Box>
            </Flex>

            <Box>
              {" "}
              <Flex
                w="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDir="column"
              >
                <Box>
                  <Button borderRadius="10px">
                    <HStack>
                      <Avatar
                        name={
                          fromDetails?.tokenName ||
                          data?.fromTokenDetails?.info?.contract_name
                        }
                        src={
                          fromDetails?.logo ||
                          data?.fromTokenDetails?.info?.logo_url
                        }
                        boxSize="20px"
                      />
                      <Box>
                        {fromDetails?.tokenSymbol ||
                          data?.fromTokenDetails?.info?.contract_ticker_symbol}
                      </Box>
                    </HStack>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Box mt="10px" color="gray.500">
              $
              {Number(
                amount * data?.fromTokenDetails?.info?.quote_rate
              )?.toFixed(3) || ""}
            </Box>

            <Box fontSize="14px" color="gray.500" mt="10px">
              You have {Number(inTokenBalance)?.toFixed(3)}{" "}
              {fromDetails?.tokenSymbol ||
                data?.fromTokenDetails?.info?.contract_ticker_symbol}{" "}
              <Box
                _hover={{ cursor: "pointer" }}
                fontWeight="600"
                color="#00a3ff"
                as="span"
                display="inline-block"
                fontSize="14px"
                onClick={() =>
                  String(inToken) ==
                  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
                    ? setAmount(Number(inTokenBalance) * 0.9)
                    : setAmount(Number(inTokenBalance))
                }
              >
                MAX
              </Box>
            </Box>
          </Flex>
        </Box>

        <Box
          bg="#f5f5f7"
          _dark={{ bg: "#15161a" }}
          w={{ base: "350px", md: "400px" }}
          pt="10px"
          pb="10px"
          pl="15px"
          pr="15px"
          mt="10px"
          borderRadius="10px"
        >
          <Flex w="100%" alignItems="flex-start" justifyContent="flex-start">
            <Box fontSize="14px" color="gray.500">
              You receive
            </Box>
          </Flex>

          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Flex
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDir="column"
            >
              <Box>
                <Input
                  readOnly
                  value={
                    isNaN(
                      Number(
                        swapData?.estimate?.toAmount /
                          10 ** swapData?.action?.toToken?.decimals
                      )?.toFixed(3)
                    )
                      ? "0"
                      : Number(
                          swapData?.estimate?.toAmount /
                            10 ** swapData?.action?.toToken?.decimals
                        )?.toFixed(3)
                  }
                  fontSize="36px"
                  placeholder="0"
                  focusBorderColor={
                    colorMode == "light" ? "#f5f5f7" : "#15161a"
                  }
                  border="none"
                  p="0px"
                />
              </Box>
            </Flex>
            <Box>
              {" "}
              <Flex
                w="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDir="column"
              >
                <Box>
                  <Button borderRadius="10px">
                    <HStack>
                      <Avatar
                        name={
                          toDetails?.tokenName ||
                          data?.toTokenDetails?.info?.symbol
                        }
                        src={
                          toDetails?.logo || data?.toTokenDetails?.info?.logo
                        }
                        boxSize="20px"
                      />
                      <Box>
                        {toDetails?.tokenSymbol ||
                          data?.toTokenDetails?.info?.symbol}
                      </Box>
                    </HStack>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Box mt="10px" color="gray.500">
              $
              {isNaN(Number(swapData?.estimate?.toAmountUSD)?.toFixed(3))
                ? "0"
                : Number(swapData?.estimate?.toAmountUSD)?.toFixed(3)}
            </Box>

            <Box fontSize="14px" color="gray.500" mt="10px">
              You have {Number(outTokenBalance)?.toFixed(3)}{" "}
              {toDetails?.tokenSymbol || data?.toTokenDetails?.info?.symbol}
            </Box>
          </Flex>
        </Box>

        <ApproveInfo
          approve={approve}
          isApproving={isApproving}
          inToken={inToken}
          swapData={swapData}
          showApprove={showApprove}
        />

        <SwapInfo
          swapData={swapData}
          gasInfo={gasInfo}
          swap={swap}
          isSwapping={isSwapping}
          recipient={recipient}
        />

        {/*  <Button
          display={
            Number(allowanceData?.allowance) <
            Number(
              amount * 10 ** data?.fromTokenDetails?.info?.contract_decimals ||
                fromDetails?.tokenDecimals
            )
              ? "yes"
              : "none"
          }
          display={
            String(inToken)?.toLowerCase() ==
            "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
              ? "none"
              : "yes"
          }
          mt="10px"
          w={{ base: "350px", md: "400px" }}
          borderRadius="10px"
          onClick={() => approve()}
          isLoading={isApproving}
          isDisabled={showApprove == false ? true : false}
        >
          Approve
        </Button> */}
        {/* 
        <Button
          isLoading={isSwapping}
          onClick={() => swap()}
          borderRadius="10px"
          mt="10px"
          w={{ base: "350px", md: "400px" }}
        >
          Swap
        </Button> */}
      </Flex>

      <Modal
        size="full"
        isOpen={isSwapStartedOpen}
        onClose={onSwapStartedClose}
      >
        <ModalOverlay />
        <ModalContent p="40px" bg="white" _dark={{ bg: "black" }}>
          <ModalHeader>
            <Flex
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box>Total remaining time</Box>
            </Flex>
          </ModalHeader>

          <ModalCloseButton borderRadius="10px" />

          <ModalBody>
            <Countdown
              durationInSeconds={swapData?.estimate?.executionDuration}
              fromTokenImage={
                fromDetails?.logo || data?.fromTokenDetails?.info?.logo_url
              }
              toTokenImage={toDetails?.logo || data?.toTokenDetails?.info?.logo}
            />

            <Flex
              w="100%"
              alignItems="center"
              justifyContent="flex-start"
            ></Flex>
          </ModalBody>

          <ModalFooter alignItems="center" justifyContent="center">
            <Box>
              Something went wrong? Send this ID to one of our staff members:{" "}
              {swapData?.id}
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isSwapCompletedOpen}
        onClose={onSwapCompletedClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="20px">
          <ModalHeader>Your swap is done!</ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <Flex
              mt="20px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box color="gray.500">Sent value</Box>
              <Box>${swapData?.estimate?.fromAmountUSD || 0}</Box>
            </Flex>

            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">Received value</Box>
              <Box>${swapData?.estimate?.toAmountUSD || 0}</Box>
            </Flex>

            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">We routed through</Box>
              <Box>
                <HStack>
                  <Avatar
                    src={swapData?.toolDetails?.logoURI}
                    borderRadius="400px"
                    boxSize="20px"
                    name={swapData?.toolDetails?.name || "MatchTrade"}
                  />
                  <Box>{swapData?.toolDetails?.name || "MatchTrade"}</Box>
                </HStack>
              </Box>
            </Flex>
            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">You got</Box>

              <Box>
                {Math.ceil(swapData?.estimate?.toAmountUSD) || "0"} MatchPoints
              </Box>
            </Flex>
            <Divider mt="10px" mb="10px" />
            <VStack w="100%">
              <Link
                w="100%"
                href={`${link}${swappingData?.hash}`}
                _hover={{ textDecoration: "none" }}
                isExternal
              >
                {" "}
                <Button borderRadius="10px" w="100%" mt="30px">
                  View on explorer
                </Button>
              </Link>

              <Button
                w="100%"
                mt="10px"
                borderRadius="10px"
                onClick={handleOpenModal}
              >
                Update Volume
              </Button>
            </VStack>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isSettingsOpen}
        onClose={onSettingsClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="20px">
          <ModalHeader>Customizations</ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">Slippage</Box>
              <Box>
                <Input
                  focusBorderColor={
                    colorMode == "light" ? "#f5f5f7" : "#15161a"
                  }
                  onChange={(e) => setSlippage(e?.target?.value)}
                  borderRadius="10px"
                  placeholder="1%"
                  _dark={{ bg: "#2b2b2b" }}
                  bg="#f5f5f7"
                  border="none"
                />
              </Box>
            </Flex>

            <Flex
              mt="10px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box color="gray.500">Recipient</Box>
              <Box>
                <Input
                  focusBorderColor={
                    colorMode == "light" ? "#f5f5f7" : "#15161a"
                  }
                  onChange={(e) => setRecipient(e?.target?.value)}
                  borderRadius="10px"
                  placeholder="your address"
                  _dark={{ bg: "#2b2b2b" }}
                  bg="#f5f5f7"
                  border="none"
                />
              </Box>
            </Flex>

            <Flex
              mt="10px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box color="gray.500">Routing</Box>
              <Box>
                <Select
                  borderRadius="10px"
                  value={typeId}
                  onChange={(e) => setTypeId(e.target.value)}
                >
                  <option value="BEST_VALUE">Best value</option>
                  <option value="BEST_FEE">Lowest swap fee</option>
                  <option value="BEST_FEE_GAS">Best gas fee</option>
                  <option value="RECOMMENDED">Recommended</option>
                </Select>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <UpdateTradeVolumeModal
        value={Number(
          amount * data?.fromTokenDetails?.info?.quote_rate
        )?.toFixed(3)}
        isOpen={isModalOpen}
        swapData={swapData}
        onClose={handleCloseModal}
      />

      <Modal
        isCentered
        size={{ base: "xs", md: "sm" }}
        isOpen={isTaxOpen}
        onClose={onTaxClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="20px">
          <ModalHeader>
            <Box
              borderRadius="20px"
              border="1px solid orange"
              pl="10px"
              pr="5px"
              pt="5px"
              pb="5px"
              w={{ base: "50%", md: "58%" }}
              fontSize="18px"
            >
              <Box color="orange" fontWeight="600">
                Tax token detected
              </Box>
            </Box>
          </ModalHeader>
          <ModalCloseButton borderRadius="10px" />
          <ModalBody>
            <Box>
              We've detected that one of the tokens you're trading has a{" "}
              <Box
                color="orange"
                fontWeight="600"
                as="span"
                display="inline-block"
              >
                {slippage - 3}%
              </Box>{" "}
              tax applied to it , so we've automatically set the slippage to{" "}
              <Box
                color="orange"
                fontWeight="600"
                as="span"
                display="inline-block"
              >
                {slippage}%
              </Box>{" "}
              to enable the swap go through seamlessly. Click{" "}
              <Button
                size="xs"
                onClick={() => {
                  onTaxClose();
                  onSettingsOpen();
                }}
              >
                here{" "}
              </Button>{" "}
              to set a manual slippage instead.
            </Box>
          </ModalBody>

          <ModalFooter
            alignItems="center"
            justifyContent="flex-start"
            color="gray.500"
          >
            <Checkbox
              colorScheme="orange"
              isChecked={hideModal}
              onChange={handleCheckboxChange}
              ml="5px"
              mt="10px"
            >
              Hide this message permanently
            </Checkbox>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*  <Button colorScheme="teal" onClick={handleOpenModal}>
        Open Modal
      </Button> */}

      {/*   <Button onClick={() => onSwapCompletedOpen()}>Do stuff</Button>
      <Button onClick={() => onSwapStartedOpen()}>Do MOAR stuff</Button>
      <Button onClick={() => handleUpdateMatchPoints()}>
        do even MOAR stuff
      </Button> */}
      {/* <Button onClick={() => updateUserCompDetails()}>BAD COMMANDO</Button> */}
      {/*   <Button onClick={() => onTaxOpen()}>BAD COMMANDO</Button> */}
    </div>
  );
};

export default Trade;
