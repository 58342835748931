import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Box,
  HStack,
  Avatar,
} from "@chakra-ui/react";
import React from "react";
import { HiLockOpen } from "react-icons/hi";
import { IoChevronForward } from "react-icons/io5";

const ApproveInfo = ({
  approve,
  isApproving,
  inToken,
  swapData,
  showApprove,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Modal
        motionPreset="slideInBottom"
        isCentered
        size={{ base: "xs", md: "sm" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="15px">
          <ModalHeader></ModalHeader>
          {/*   <ModalCloseButton /> */}
          <ModalBody>
            <Flex
              w="100%"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
            >
              <HiLockOpen size="60px" />
              <Box fontSize="22px" mt="10px" fontWeight="600">
                Allow access
              </Box>
              <Box fontSize="14px" textAlign="center" color="gray.500">
                To continue your swap, please allow MatchTrade to access the
                below amount of {swapData?.action?.fromToken?.symbol}.
              </Box>

              <Flex
                mt="20px"
                w="100%"
                flexDir="column"
                alignItems="center"
                position="relative"
              >
                <Box
                  bg="#e3f8ff"
                  mb="-20px"
                  p="8px"
                  zIndex="2"
                  position="relative"
                  borderRadius="15px"
                  color="#01b2ff"
                  border="3px solid white"
                  _dark={{ border: "3px solid #15161a " }}
                >
                  Approval Request
                </Box>
                <Box
                  w="100%"
                  _dark={{ bg: "#26272d" }}
                  bg="#f5f5f7"
                  h="150px"
                  zIndex="1"
                  position="relative"
                  borderRadius="20px"
                  p="30px"
                >
                  <Flex alignItems="center" justifyContent="center">
                    <Box mt="-10px">
                      {" "}
                      <HiLockOpen />
                    </Box>
                    <Box
                      noOfLines={1}
                      ml="10px"
                      fontSize="50px"
                      fontWeight="600"
                    >
                      {isNaN(
                        Number(
                          swapData?.estimate?.fromAmount /
                            10 ** swapData?.action?.fromToken?.decimals
                        )?.toFixed(3)
                      )
                        ? "0"
                        : Number(
                            swapData?.estimate?.fromAmount /
                              10 ** swapData?.action?.fromToken?.decimals
                          )?.toFixed(3)}
                    </Box>
                  </Flex>

                  <Flex alignItems="center" justifyContent="center">
                    <HStack>
                      <Avatar
                        name={swapData?.action?.fromToken?.symbol}
                        src={swapData?.action?.fromToken?.logoURI}
                        borderRadius="300px"
                        boxSize="18px"
                      />
                      <Box fontWeight="600">
                        {swapData?.action?.fromToken?.symbol}
                      </Box>
                    </HStack>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="space-between">
            <Button
              _dark={{ bg: "#26272d" }}
              bg="#f5f5f7"
              flex="1"
              mr={2}
              onClick={onClose}
              borderRadius="12px"
            >
              Cancel
            </Button>
            <Button
              isLoading={isApproving}
              borderRadius="12px"
              onClick={() => approve()}
              flex="1"
              bg="black"
              color="white"
              _dark={{ bg: "white", color: "black" }}
              _hover={{
                bg: "black",
                color: "white",
                _dark: { bg: "white", color: "black" },
              }}
            >
              Approve
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button
        h="50px"
        isDisabled={swapData == "" ? true : false}
        mt="10px"
        w={{ base: "350px", md: "400px" }}
        borderRadius="12px"
        onClick={onOpen}
        display={
          String(inToken)?.toLowerCase() ==
          "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
            ? "none"
            : "yes"
        }
      >
        <Flex
          w={{ base: "300px", md: "350px" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>Approve</Box>
          <Box
            _hover={{ transform: "translateX(5px)" }}
            transition="transform 0.2s ease-in-out"
          >
            <IoChevronForward />
          </Box>
        </Flex>
      </Button>
    </div>
  );
};

export default ApproveInfo;
