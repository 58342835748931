import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import {
  Box,
  Flex,
  Avatar,
  Input,
  HStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useColorMode,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
  Image,
} from "@chakra-ui/react";
import { HiArrowLongRight } from "react-icons/hi2";
import { RiArrowDropDownLine } from "react-icons/ri";
import { ethers } from "ethers";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import AddToken from "../components/AddToken";
import { fetchUserByAddress } from "../helpers/getUserData";
import { useAccount } from "wagmi";

const TradeStep1 = () => {
  const [geckoTokens, setGeckoTokens] = useState([]);
  const { address } = useAccount();
  const [Ca2, setCa2] = useState("");
  const [decimals, setDecimals] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tokens, setTokens] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const data = location.state?.data;
  const data2 = location.state?.data2;
  const data3 = location.state?.data3;
  const [symbol, setSymbol] = useState(data?.contract_ticker_symbol);
  const [logo, setLogo] = useState(data?.logo_url);
  const [logo2, setLogo2] = useState("");
  const [symbol2, setSymbol2] = useState("");
  const [customTokens, setCustomTokens] = useState([]);
  const handleTokenClick = () => {
    onClose();
  };

  function formatBalanceWithSuffix(balance, contractDecimals) {
    const num = ethers.utils.formatUnits(balance, contractDecimals);
    const numValue = parseFloat(num);

    if (numValue >= 1e6) {
      return (numValue / 1e6).toFixed(2) + "M";
    } else if (numValue >= 1e3) {
      return (numValue / 1e3).toFixed(2) + "K";
    } else {
      return numValue.toFixed(2);
    }
  }

  const fetchCustomTokens = async () => {
    try {
      const user = await fetchUserByAddress(address);
      const tokens = user?.customTokens;
      setCustomTokens(tokens);
    } catch (e) {}
  };

  setInterval(() => {
    fetchCustomTokens();
  }, 10000);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await axios
          .get(
            "https://serverrrrr-0tkv.onrender.com/matchTrade-supported-token-list",
            {
              params: {
                serverName: data3,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": process.env.REACT_APP_API_KEY,
              },
            }
          )
          .then((data) => setTokens(data?.data?.data));
      } catch (e) {}
    };

    fetchTokens();
    onOpen();
  }, []);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = tokens.filter((item) => {
      return (
        item?.symbol?.toLowerCase()?.includes(query?.toLowerCase()) ||
        item?.address?.toLowerCase()?.includes(query?.toLowerCase()) ||
        item?.name?.toLowerCase()?.includes(query?.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    setFilteredData(tokens);
  }, [tokens]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get("https://tokens.coingecko.com/uniswap/all.json")
          .then((data) => {
            setGeckoTokens(data?.data?.tokens);
          });
      } catch (e) {}
    };
    fetchData();
  }, []);

  return (
    <div>
      <AppHeader />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Flex
          w={{ base: "90%", md: "35%" }}
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDir="column"
        >
          <Box fontSize={{ base: "25px", md: "25px" }}>
            You're going from
            <Box
              display={{ base: "none", md: "inline-block" }}
              mt="25px"
              ml="25px"
              as="span"
            >
              <HiArrowLongRight />
            </Box>
            <Popover>
              <PopoverTrigger>
                <Button display="inline-block" borderRadius="8px" ml="25px">
                  <Box>
                    <HStack>
                      <Avatar
                        name={data?.contract_name}
                        src={logo}
                        boxSize="20px"
                      />

                      <Box>{symbol}</Box>
                      <Box>
                        <RiArrowDropDownLine />
                      </Box>
                    </HStack>
                  </Box>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                maxH="300px"
                overflowY="scroll"
                borderRadius="15px"
                _dark={{ bg: "#15161a" }}
                bg="#f9f9f9"
                fontSize="15px"
                w="250px"
              >
                <PopoverBody>
                  {data2?.map((e, i) => {
                    return (
                      <>
                        <Flex
                          onClick={() => {
                            setLogo(e?.logo_url);
                            setSymbol(e?.contract_ticker_symbol);
                          }}
                          p="10px"
                          w="100%"
                          alignItems="center"
                          justifyContent="space-between"
                          mt="10px"
                          _hover={{
                            bg: colorMode == "dark" ? "#2B2B2B" : "#E9E9E9",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <Avatar
                            name={e?.contract_name}
                            src={e?.logo_url}
                            boxSize="25px"
                          />

                          <Flex
                            flexDir="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                          >
                            <Box fontWeight="600">
                              {formatBalanceWithSuffix(
                                e?.balance,
                                e?.contract_decimals
                              )}{" "}
                              <Box
                                as="span"
                                display="inline-block"
                                color="gray.500"
                              >
                                {" "}
                                {e?.contract_ticker_symbol}
                              </Box>
                            </Box>
                          </Flex>
                        </Flex>
                      </>
                    );
                  })}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>

          <Divider w={{ base: "90%", md: "35%" }} mt="30px" mb="10px" />
          <motion.div
            initial={{ opacity: 1, y: 0 }}
            animate={{
              opacity: isOpen ? 1 : 0,
              y: isOpen ? 0 : -100,
            }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ type: "tween", duration: 0.3 }}
          >
            <Box
              fontSize={{ base: "25px", md: "25px" }}
              mt={{ base: "50px", md: "60px" }}
            >
              Now select a token to swap to
            </Box>
          </motion.div>

          <AnimatePresence>
            {!isOpen && (
              <motion.div
                key="hi"
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 100 }}
                transition={{ type: "tween", duration: 0.5 }}
              >
                <Box mt={{ base: "-50px", md: "-80px" }} fontSize="25px">
                  <Box fontSize={{ base: "25px", md: "25px" }}>
                    To
                    <Box
                      display={{ base: "none", md: "inline-block" }}
                      mt="25px"
                      ml="25px"
                      as="span"
                    >
                      <HiArrowLongRight />
                    </Box>
                    <Button
                      display="inline-block"
                      borderRadius="8px"
                      ml="25px"
                      onClick={onOpen}
                    >
                      <Box>
                        <HStack>
                          <Avatar
                            name={data?.contract_name}
                            src={logo2}
                            boxSize="20px"
                          />

                          <Box>{symbol2}</Box>
                          <Box>
                            <RiArrowDropDownLine />
                          </Box>
                        </HStack>
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {!isOpen && (
              <motion.div
                key="hi"
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 100 }}
                transition={{ type: "tween", duration: 0.5 }}
              >
                <Link
                  state={{
                    data: {
                      fromTokenDetails: {
                        info: data,
                      },
                      toTokenDetails: {
                        info: {
                          symbol: symbol2,
                          logo: logo2,
                          address: Ca2,
                          decimals: decimals,
                        },
                      },
                      gecko: geckoTokens,
                    },
                    data2: data3,
                  }}
                  to={`/app/trade?tokenIn=${data?.contract_address}&tokenOut=${Ca2}`}
                  _hover={{ textDecoration: "none" }}
                >
                  <Button
                    mt="20px"
                    borderRadius="10px"
                    w={{ base: "250%", md: "300%" }}
                    rightIcon={<HiArrowLongRight />}
                  >
                    Continue
                  </Button>
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </Flex>
      </Flex>

      <Drawer
        size="sm"
        isCentered
        isOpen={isOpen}
        placement="bottom"
        onClose={onClose}
        display={{ base: "none", md: "flex" }}
      >
        {/*       <DrawerOverlay /> */}
        <DrawerContent
          borderTopRightRadius="20px"
          borderTopLeftRadius="20px"
          sx={{
            "@media screen and (min-width: 48em)": {
              width: "35%",
              left: "50%",
              transform: "translateX(-50%)",
              margin: "0 auto",
            },
            "@media screen and (max-width: 48em)": {
              width: "100%",
              margin: "0 auto",
            },
          }}
          bg="#f9f9f9"
          _dark={{ bg: "#15161a" }}
          h="65%"
        >
          <DrawerCloseButton borderRadius="10px" />
          <DrawerHeader></DrawerHeader>

          <DrawerBody>
            <Input
              value={searchQuery}
              onChange={handleSearchInputChange}
              mt="30px"
              bg="white"
              _dark={{ bg: "#2B2B2B" }}
              borderRadius="10px"
              placeholder="Search by symbol or address"
            />
            <Box color="gray.500" mt="10px">
              Your custom tokens
            </Box>

            <Box
              mt="10px"
              border="2px dashed #E9E9E9"
              borderStyle="dashed 10px"
              _dark={{ border: "2px dashed #2b2b2b " }}
              borderRadius="20px"
              p="10px"
              maxH="210px"
              overflow="auto"
            >
              {customTokens?.length == 0 ? (
                <>
                  {" "}
                  <Flex
                    w="100%"
                    mt="50px"
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image src="https://investdex.io/static/media/lock_Icon.2406b9f1.svg" />
                    <Box mt="30px" color="gray.500">
                      No custom tokens.
                    </Box>
                  </Flex>
                </>
              ) : (
                <>
                  {customTokens?.map((e, i) => {
                    return (
                      <>
                        <HStack
                          onClick={() => {
                            setSymbol2(e?.tokenSymbol);
                            setLogo2(e?.tokenLogo);
                            setCa2(e?.tokenAddress);
                            handleTokenClick();
                          }}
                          spacing="20px"
                          w="100%"
                          p="10px"
                          mt="10px"
                          borderRadius="10px"
                          _hover={{
                            bg: colorMode == "dark" ? "#2B2B2B" : "#E9E9E9",
                            borderRadius: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <Box>
                            <Avatar
                              name={e?.tokenName}
                              src={e?.tokenLogo}
                              boxSize="30px"
                            />
                          </Box>
                          <Flex
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            flexDir="column"
                          >
                            <Box>{e?.tokenSymbol}</Box>
                            <Box color="gray.500">{e?.tokenName}</Box>
                          </Flex>
                        </HStack>
                      </>
                    );
                  })}
                </>
              )}
            </Box>

            <Box color="gray.500" mt="10px">
              Other tokens
            </Box>

            {filteredData?.map((e, i) => {
              return (
                <>
                  <HStack
                    onClick={() => {
                      setLogo2(e?.icon);
                      setSymbol2(e?.symbol);
                      setCa2(e?.address);
                      setDecimals(e?.decimals);
                      handleTokenClick();
                    }}
                    spacing="20px"
                    w="100%"
                    p="10px"
                    mt="10px"
                    borderRadius="10px"
                    _hover={{
                      bg: colorMode == "dark" ? "#2B2B2B" : "#E9E9E9",
                      borderRadius: "12px",
                      cursor: "pointer",
                    }}
                  >
                    <Box>
                      <Avatar name={e?.name} src={e?.icon} boxSize="30px" />
                    </Box>
                    <Flex
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDir="column"
                    >
                      <Box>{e?.symbol}</Box>
                      <Box color="gray.500">{e?.name}</Box>
                    </Flex>
                  </HStack>
                </>
              );
            })}
          </DrawerBody>

          <DrawerFooter alignItems="center" justifyContent="center">
            <HStack>
              <Box>Didn't find your desired token?</Box>
              <AddToken />
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default TradeStep1;
