import axios from "axios";

export const fetchUserByAddress = async (address) => {
  try {
    const response = await axios.get(
      "https://matchtrade-server.onrender.com/api/getUser",
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    // Assuming the response data is an array of user objects
    const userData = response.data;

    // Find the user object with the specified address
    const foundUser = userData.find((user) => user.ethAddress === address);

    return foundUser; // Return the found user object or undefined if not found
  } catch (error) {}
};
