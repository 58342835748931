import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Flex,
  Button,
  useToast,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import axios from "axios";

const AdminDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ca = "0xE3DA0a3D75Ec8Ca93D2de8D8ab5D6C5CB45fa41b";
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [abi, setAbi] = useState([]);
  const [contract, setContract] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [address, setAddress] = useState("");
  const [balanceHouse, setBalanceHouse] = useState("");
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        await axios
          .get("https://matchtrade-server.onrender.com/api/getABI", {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          })
          .then((data) => setAbi(data.data.abi));
      } catch (e) {}
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    const newContract = new ethers.Contract(ca, abi, signer);
    setContract(newContract);
  }, [abi]);

  const approveAddresses = async () => {
    setIsFetching(true);
    try {
      await contract.authorizeFinalizer(String(address));
      setIsFetching(false);
      toast({
        title: "Fetched all!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      console.log(e);
      setIsFetching(false);
      toast({
        title: "See console",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const fetchInfo = async () => {
    try {
      const bals = await contract.houseBalance();
      setBalanceHouse(ethers.utils.formatEther(String(bals)));

      toast({
        title: "Fetched house balance",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      console.log(e);

      toast({
        title: "See console",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const withdrawHouseBalance = async () => {
    setIsWithdrawing(true);
    try {
      const bals = await contract.withdrawHouseBalance();
      toast({
        title: "Withdrawal successful",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsWithdrawing(false);
    } catch (e) {
      console.log(e);
      setIsWithdrawing(false);

      toast({
        title: "See console",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [contract]);

  return (
    <div>
      <Button onClick={onOpen} size="sm" mb="10px" borderRadius="10px">
        Info
      </Button>
      <Drawer
        isOpen={isOpen}
        placement={{ base: "bottom", md: "right" }}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent _dark={{ bg: "#15161a" }}>
          <DrawerCloseButton />
          <DrawerHeader>Authorize addresses</DrawerHeader>

          <DrawerBody>
            <Input
              onChange={(e) => setAddress(e.target.value)}
              placeholder="enter address"
              borderRadius="10px"
            />
            <Button
              onClick={() => approveAddresses()}
              isLoading={isFetching}
              w="100%"
              borderRadius="10px"
              mt="10px"
            >
              Authorize
            </Button>

            <Divider mt="20px" />

            {/*  <DrawerHeader>Information</DrawerHeader> */}
            <Box mt="20px" color="gray.500">
              House Balance
            </Box>
            <Box fontSize="40px">{String(balanceHouse)}ETH</Box>
            <Button
              isLoading={isWithdrawing}
              onClick={() => withdrawHouseBalance()}
              w="100%"
              borderRadius="10px"
            >
              Withdraw house balance
            </Button>
          </DrawerBody>

          <DrawerFooter>
            {/*  <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AdminDrawer;
