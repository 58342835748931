import { Flex, Box, Button, Link } from "@chakra-ui/react";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const NotFound = () => {
  return (
    <div>
      <Header />

      <Flex
        w="100%"
        h="100vh" // Adjust the height of the container
        alignItems="center"
        justifyContent="center"
        flexDir="column"
      >
        <Box textAlign="center">
          <Box fontSize="6xl">404</Box>
          <Box fontSize="2xl">Not Found</Box>
          <Link href="/" _hover={{ textDecoration: "none" }}>
            <Button borderRadius="10px" mt="10px">
              Go back home
            </Button>
          </Link>
        </Box>
      </Flex>

      <Footer />
    </div>
  );
};

export default NotFound;
