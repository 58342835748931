import React, { useState, useEffect } from "react";
import { Box, Input, Flex, Button, useToast } from "@chakra-ui/react";
import { ethers } from "ethers";
import axios from "axios";
import AppHeader from "../components/AppHeader";
import AdminDrawer from "./AdminDrawer";

const MatchAdmin = () => {
  const [passWord, setPassWord] = useState("");
  const ca = "0xE3DA0a3D75Ec8Ca93D2de8D8ab5D6C5CB45fa41b";
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [abi, setAbi] = useState([]);
  const [contract, setContract] = useState("");
  const [biz, setBiz] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await axios
          .get("https://matchtrade-server.onrender.com/api/getABI", {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          })
          .then((data) => setAbi(data.data.abi));
      } catch (e) {}
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    const newContract = new ethers.Contract(ca, abi, signer);
    setContract(newContract);
  }, [abi]);

  const fetchUnfinishedBusiness = async () => {
    setIsFetching(true);
    try {
      const unfinished = await contract.getUnfinalizedCompetitionCount();
      setBiz(unfinished);
      console.log(unfinished);
      setIsFetching(false);
      toast({
        title: "Fetched all!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      console.log(e);
      setIsFetching(false);
      toast({
        title: "See console",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const finalizeAll = async () => {
    setIsFinalizing(true);
    try {
      await contract.finalizePendingCompetitions();
      setIsFinalizing(false);
      toast({
        title: "Finalized all!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      console.log(e);
      setIsFinalizing(false);
      toast({
        title: "See console",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  /*   setInterval(() => {
    fetchUnfinishedBusiness();
  }, 10000);
 */
  return (
    <div>
      <AppHeader />
      {String(passWord) === String(process.env.REACT_APP_ADMIN_PASSWORD) ? (
        <>
          <Flex
            height="100vh"
            alignItems="center"
            justifyContent="center"
            flexDir="column"
          >
            <Flex
              w={{ base: "80%", md: "20%" }}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <AdminDrawer />
            </Flex>
            <Box
              w={{ base: "80%", md: "20%" }}
              p="10px"
              bg="#f5f5f5"
              _dark={{ bg: "#15161a" }}
              borderRadius="10px"
            >
              <Flex
                w="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDir="column"
              >
                <Box fontSize="12px">Unfinished business</Box>
                <Box fontSize="40px">{String(biz)}</Box>
              </Flex>
            </Box>
            <Button
              isLoading={isFetching}
              mt="10px"
              w={{ base: "80%", md: "20%" }}
              borderRadius="10px"
              onClick={() => fetchUnfinishedBusiness()}
            >
              Fetch
            </Button>
            <Button
              isLoading={isFinalizing}
              mt="10px"
              w={{ base: "80%", md: "20%" }}
              borderRadius="10px"
              onClick={() => finalizeAll()}
            >
              Finalize all pending
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Flex height="100vh" alignItems="center" justifyContent="center">
            <Box>
              <Input
                borderRadius="10px"
                onChange={(e) => String(setPassWord(e.target.value))}
                placeholder="Enter password"
              />
            </Box>
          </Flex>
        </>
      )}
    </div>
  );
};

export default MatchAdmin;
