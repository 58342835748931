import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  HStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import AppHeader from "../components/AppHeader";

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await axios.get(
        "https://serverrrrr-0tkv.onrender.com/api/getUsers",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      const data = response.data;
      // Sort users based on matchPoints in descending order
      const sortedData = data.sort((a, b) => b.matchPoints - a.matchPoints);
      setLeaderboardData(sortedData);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  return (
    <>
      <AppHeader />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Flex
          w={{ base: "99%", md: "90%", lg: "60%" }}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Box
            mt="10px"
            p="15px"
            borderRadius="15px"
            w="100%"
            maxH={"100vh"}
            overflowY="scroll"
          >
            <TableContainer w="100%">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Rank</Th>
                    <Th>Username</Th>
                    <Th>Match Points</Th>
                    <Th>User Trades Count</Th>
                    <Th>User Comps Count</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {leaderboardData.map((item, index) => (
                    <Tr key={item._id}>
                      <Td>
                        <HStack>
                          <Image
                            borderRadius="10px"
                            border="5px solid #f5f5f7"
                            _dark={{ border: "5px solid #2b2b2b" }}
                            src={`https://www.gravatar.com/avatar/${index}?d=identicon`}
                            boxSize={{ base: "30px", md: "30px" }}
                          />
                          <Box> {index + 1}</Box>
                        </HStack>
                      </Td>
                      <Td>{item.name}</Td>
                      <Td>{item.matchPoints}</Td>
                      <Td>{item.userTrades.length}</Td>
                      <Td>{item.userComps.length}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Leaderboard;
