import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import axios from "axios";
import AppHeader from "../components/AppHeader";
import {
  Box,
  Flex,
  HStack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  VStack,
  useToast,
  Image,
  Divider,
  Avatar,
  Stack,
  Icon,
  useColorMode,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Badge,
  Link,
} from "@chakra-ui/react";
import { fetchUserByAddress } from "../helpers/getUserData";
import moment from "moment";
import { ChevronRightIcon, InfoIcon } from "@chakra-ui/icons";
import { MdPending, MdOutlineCheckCircleOutline } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { BsThreeDots } from "react-icons/bs";
import { BiChevronRightCircle } from "react-icons/bi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useDisconnect } from "wagmi";

const Account = ({ price }) => {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const [userData, setUserData] = useState({});
  const [comps, setComps] = useState([]);
  const [comps2, setComps2] = useState([]);
  const [comps3, setComps3] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sortedComps, setSortedComps] = useState([]);
  const [modalDeets, setModalDeets] = useState({});
  /*   const ca = "0x8d2Ff13de8C05605d7dd9998c224E129865d021A"; */
  const ca = "0xD546aE409025Ad6405a8E28e6C96375C7A37697C";
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [abi, setAbi] = useState([]);
  const [contract, setContract] = useState("");
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [isClaiming, setIsClaiming] = useState(false);
  const [isRefunding, setIsRefunding] = useState(false);
  const [isClaimingRefund, setIsClaimingRefund] = useState(false);
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokens = await axios
          .get("https://serverrrrr-0tkv.onrender.com/api/getABI", {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          })
          .then((data) => setAbi(data.data.abi));
      } catch (e) {}
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    const newContract = new ethers.Contract(ca, abi, signer);
    setContract(newContract);
  }, [abi]);
  const getUser = async () => {
    const data = await fetchUserByAddress(address);
    setUserData(data);
  };

  useEffect(() => {
    getUser();
  }, [address]);

  const timeAgo = (givenDate) => {
    const currentDate = moment();
    const startDate = moment(givenDate);
    const duration = moment.duration(currentDate.diff(startDate));

    const days = duration.asDays();
    const hours = duration.asHours();
    const minutes = duration.asMinutes();

    if (days >= 1) {
      return `${Math.floor(days)} days ago`;
    } else if (hours >= 1) {
      return `${Math.floor(hours)} hours ago`;
    } else {
      return `${Math.floor(minutes)} minutes ago`;
    }
  };

  const fetchAllChallenges = async () => {
    setInterval(async () => {
      try {
        const provider2 = new ethers.providers.JsonRpcProvider(
          "https://base.llamarpc.com"
        );
        const contracte = new ethers.Contract(ca, abi, provider2);
        const challenges = await contracte.getCompetitionsByType(0);
        const challenges2 = await contracte.getCompetitionsByType(1);
        const challenges3 = await contracte.getCompetitionsByType(2);

        setComps(challenges);
        setComps2(challenges2);
        setComps3(challenges3);
      } catch (e) {}
    }, 5000);
  };

  useEffect(() => {
    fetchAllChallenges();
  }, [contract]);

  useEffect(() => {
    if (comps) {
      try {
        const competitionObjects = comps.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: competition.endTime,
          challengeAmount: competition.challengeAmount,
          creatorVolume: competition.creatorVolume,
          challengerVolume: competition.challengerVolume,
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: competition.competitionId,
        }));

        const competitionObjects2 = comps2.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: competition.endTime,
          challengeAmount: competition.challengeAmount,
          creatorVolume: competition.creatorVolume,
          challengerVolume: competition.challengerVolume,
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: competition.competitionId,
        }));

        const competitionObjects3 = comps3.map((competition, index) => ({
          creator: competition.creator,
          challenger: competition.challenger,
          endTime: competition.endTime,
          challengeAmount: competition.challengeAmount,
          creatorVolume: competition.creatorVolume,
          challengerVolume: competition.challengerVolume,
          state: competition.state,
          claimed: competition.claimed,
          winner: competition.winner,
          canceler: competition.canceler,
          competitionType: competition.competitionType,
          index: competition.competitionId,
        }));

        const newArray = competitionObjects.concat(
          competitionObjects2,
          competitionObjects3
        );

        setSortedComps(newArray);
      } catch (e) {}
    }
  }, [comps]);

  const filteredArray = sortedComps.filter(
    (obj) =>
      String(obj.creator) === String(address) ||
      String(obj.challenger) === String(address)
  );

  function formatTimestamp(unixTimestamp) {
    const time = moment.unix(unixTimestamp);
    const formattedTime = time.format("ddd Do, HH:mm a");
    return formattedTime;
  }

  const getDetails = (state) => {
    if (Number(state) === 0) {
      const info = {
        color: "#e3f8ff",
        iconColor: "#01b2ff",
        icon: MdPending,
        text: "Pending",
      };
      return info;
    } else if (Number(state) === 1) {
      const info = {
        color: "#dcfaec",
        iconColor: "#00ca48",
        icon: MdOutlineCheckCircleOutline,
        text: "Completed",
      };
      return info;
    } else {
      const info = {
        color: "#fff5e0",
        iconColor: "#ff9601",
        icon: RxCrossCircled,
        text: "Cancelled",
      };
      return info;
    }
  };

  const getWording = (word) => {
    if (Number(word) === 0) {
      return "Volume";
    } else if (Number(word) === 1) {
      return "PnL";
    } else if (Number(word) === 2) {
      return "Trades";
    }
  };

  const claimIndividualPrize = async () => {
    try {
      setIsClaiming(true);
      const tx = await contract.claimIndividualPrize(modalDeets?.index);
      await tx.wait();
      toast({
        title: "Claimed successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsClaiming(false);
    } catch (error) {
      setIsClaiming(false);
      toast({
        title: "Claim failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const claimIndividualRefund = async () => {
    try {
      setIsClaimingRefund(true);
      const tx = await contract.claimRefundForUnacceptedCompetitions(
        modalDeets?.index
      );
      await tx.wait();
      toast({
        title: "Claimed refund successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsClaimingRefund(false);
    } catch (error) {
      setIsClaimingRefund(false);
      toast({
        title: "Claim refund failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleCopyToClipboard = () => {
    const formattedAddress = `https://matchtrade.org/competitions/${modalDeets?.index}`;
    navigator.clipboard
      .writeText(formattedAddress)
      .then(() => {
        toast({
          description: "Comp details copied!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {});
  };

  return (
    <div>
      {" "}
      <AppHeader />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Flex
          w={{ base: "350px", md: "850px", lg: "840px" }}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button
            borderRadius="10px"
            onClick={() => disconnect()}
            leftIcon={<AiOutlinePoweroff />}
          >
            Log Out
          </Button>
        </Flex>
      </Flex>
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Stack mt="20px" direction={{ base: "column", md: "row" }}>
          <Box
            h={{ base: "270px", md: "280px" }}
            w={{ base: "350px", md: "400px" }}
            borderRadius="20px"
            bg="#f5f5f5"
            _dark={{ bg: "#15161a" }}
            p="15px"
          >
            <Flex
              flexDir="column"
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box fontSize={{ base: "24px", md: "30px" }}>Personal Info</Box>
            </Flex>

            <Flex
              mt="20px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box color="gray.500">Account created</Box>
              <Box>{timeAgo(userData?.createdAt)}</Box>
            </Flex>
            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">Address</Box>
              <Box>{address?.slice(0, 7) + "...." + address?.slice(-6)}</Box>
            </Flex>
            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">Username</Box>
              <Box>{userData?.name}</Box>
            </Flex>
            <Divider mt="10px" mb="10px" />

            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Box color="gray.500">MatchPoints</Box>
              <Box>{userData?.matchPoints}</Box>
            </Flex>
          </Box>

          <Box
            h={{ base: "270px", md: "280px" }}
            w={{ base: "350px", md: "450px", lg: "420px" }}
            borderRadius="20px"
            bg="#f5f5f5"
            _dark={{ bg: "#15161a" }}
            p="15px"
            overflow="auto"
          >
            <Flex
              flexDir="column"
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              p="12px"
            >
              <Box fontSize={{ base: "24px", md: "30px" }}>Recent trades</Box>
              {userData?.userTrades?.length == 0 ? (
                <>
                  <Flex
                    w="100%"
                    mt="50px"
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image src="https://investdex.io/static/media/lock_Icon.2406b9f1.svg" />
                    <Box mt="30px" color="gray.500">
                      No trades yet.
                    </Box>
                  </Flex>
                </>
              ) : (
                <>
                  {userData?.userTrades?.map((e) => {
                    return (
                      <HStack
                        onClick={() => window.location.assign(e?.hash)}
                        _hover={{
                          bg: colorMode == "dark" ? "#2B2B2B" : "#E9E9E9",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        mt="10px"
                        w="100%"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <Avatar
                            name={e?.fromTokenSymbol}
                            src={e?.fromTokenLogo}
                            boxSize="30px"
                            borderRadius="300px"
                          />
                          <Avatar
                            bg="gray.200"
                            _dark={{ bg: "white" }}
                            ml="-10px"
                            name={e?.toTokenSymbol}
                            src={e?.toTokenLogo}
                            boxSize="30px"
                            borderRadius="300px"
                          />
                          <Avatar
                            mt="20px"
                            ml="-10px"
                            boxSize="15px"
                            borderRadius="300px"
                            src={
                              e?.chain == "base"
                                ? "https://cdn.via.exchange/networks/base.svg"
                                : "https://cdn.via.exchange/networks/Ethereum.svg"
                            }
                          />
                        </Flex>

                        <Flex
                          flexDir="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Box fontSize="13px" color="gray.500">
                            Swapped
                          </Box>
                          <HStack>
                            <Box fontWeight="600">{e?.fromTokenSymbol}</Box>
                            <Box color="gray.500">
                              {" "}
                              <BiChevronRightCircle />
                            </Box>

                            <Box fontWeight="600">{e?.totokenSymbol}</Box>
                          </HStack>
                        </Flex>

                        <Flex
                          flexDir="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Box fontSize="13px" color="gray.500">
                            {Number(e?.fromTokenAmount)?.toFixed(3)}{" "}
                            {e?.fromTokenSymbol}
                          </Box>

                          <Box color="#00ca48" fontSize="20px" fontWeight="600">
                            +{Number(e?.toTokenAmount)?.toFixed(3)}{" "}
                            {e?.totokenSymbol}
                          </Box>
                        </Flex>
                      </HStack>
                    );
                  })}
                </>
              )}
            </Flex>
          </Box>
        </Stack>
      </Flex>
      <Flex mt="10px" w="100%" alignItems="center" justifyContent="center">
        <Box
          h={{ base: "270px", md: "280px" }}
          w={{ base: "350px", md: "850px", lg: "840px" }}
          borderRadius="20px"
          bg="#f5f5f5"
          _dark={{ bg: "#15161a" }}
          p="15px"
          overflow="auto"
        >
          <Flex
            flexDir="column"
            w="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box fontSize={{ base: "24px", md: "30px" }}>Your Matches</Box>
            {filteredArray?.length == 0 ? (
              <>
                <Flex
                  w="100%"
                  mt="50px"
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image src="https://investdex.io/static/media/lock_Icon.2406b9f1.svg" />
                  <Box mt="30px" color="gray.500">
                    You haven't challenged anyone yet.
                  </Box>
                </Flex>
              </>
            ) : (
              <>
                {" "}
                {filteredArray?.map((e, i) => {
                  return (
                    <Flex
                      _hover={{
                        bg: colorMode == "dark" ? "#2B2B2B" : "#E9E9E9",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                      mt="10px"
                      onClick={() => {
                        setModalDeets(e);

                        onOpen();
                      }}
                    >
                      <HStack>
                        <Image
                          borderRadius="10px"
                          border="5px solid #f5f5f7"
                          _dark={{ border: "5px solid #15161a" }}
                          src={`https://www.gravatar.com/avatar/${e?.challengeAmount}?d=identicon`}
                          boxSize={{ base: "40px", md: "40px" }}
                        />
                        <Flex
                          flexDir="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Box>
                            {ethers.utils.formatEther(
                              String(e?.challengeAmount)
                            )}{" "}
                            ETH
                          </Box>
                          <Box color="gray.500" fontSize="12px">
                            Created by:{" "}
                            <Box
                              color="black"
                              _dark={{ color: "white" }}
                              as="span"
                              display="inline-block"
                            >
                              {" "}
                              {String(e?.creator) == String(address)
                                ? "you"
                                : e?.creator?.slice(0, 7) +
                                  "...." +
                                  e?.creator?.slice(-6)}
                            </Box>
                          </Box>
                        </Flex>
                      </HStack>
                      <VStack
                        alignItems="center"
                        justifyContent="center"
                        spacing="0"
                      >
                        {String(e?.endTime) < String(new Date().getTime()) ? (
                          <Badge>ENDED</Badge>
                        ) : (
                          <Badge colorScheme="teal">ONGOING</Badge>
                        )}
                        {/*   <Box>
                          <ChevronRightIcon />
                        </Box> */}
                      </VStack>
                    </Flex>
                  );
                })}
              </>
            )}
          </Flex>
        </Box>
      </Flex>
      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg="white" _dark={{ bg: "#15161a" }} borderRadius="15px">
          <ModalHeader>
            <HStack alignItems="center" justifyContent="space-between">
              <Box>Challenge Details</Box>
              <Box _hover={{ cursor: "pointer" }}>
                <Popover fontSize="15px">
                  <PopoverTrigger>
                    <BsThreeDots />
                  </PopoverTrigger>
                  <PopoverContent
                    ml="180px"
                    mt="50px"
                    _dark={{ bg: "#15161a" }}
                    bg="#f9f9f9"
                    w="180px"
                    p="10px"
                    borderRadius="15px"
                  >
                    <PopoverBody>
                      <Button
                        isLoading={isClaiming}
                        onClick={() => claimIndividualPrize()}
                        isDisabled={
                          String(modalDeets?.winner) == String(address)
                            ? false
                            : true
                        }
                        borderRadius="10px"
                      >
                        Claim reward
                      </Button>
                      <Button
                        isLoading={isClaimingRefund}
                        onClick={() => claimIndividualRefund()}
                        /* isDisabled={
                          String(modalDeets?.winner) == String(address)
                            ? false
                            : true
                        } */
                        borderRadius="10px"
                      >
                        Claim refund
                      </Button>
                      <Button isDisabled borderRadius="10px" mt="10px">
                        Cancel comp
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            </HStack>
          </ModalHeader>

          <ModalBody>
            <HStack
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <Box color="gray.500 " _dark={{ color: "#7f7f7f" }}>
                Created by:
              </Box>
              <Box>
                {String(modalDeets?.creator) == String(address)
                  ? "you"
                  : modalDeets?.creator?.slice(0, 7) +
                    "...." +
                    modalDeets?.creator?.slice(-6)}
              </Box>
            </HStack>

            <HStack
              mt="10px"
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <Box color="gray.500" _dark={{ color: "#7f7f7f" }}>
                Ends:
              </Box>
              <Box>{formatTimestamp(String(modalDeets?.endTime))}</Box>
            </HStack>

            <HStack
              mt="10px"
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <Box color="gray.500" _dark={{ color: "#7f7f7f" }}>
                Challenger:
              </Box>
              <Box>
                {String(modalDeets?.challenger) == String(address)
                  ? "you"
                  : modalDeets?.challenger?.slice(0, 7) +
                    "...." +
                    modalDeets?.challenger?.slice(-6)}
              </Box>
            </HStack>

            <Box
              mt="10px"
              p="20px"
              h="150px"
              borderRadius="20px"
              w="100%"
              border="2px dashed #f5f5f7"
              borderStyle="dashed 10px"
              _dark={{ border: "2px dashed #2b2b2b " }}
            >
              <VStack>
                <Box fontWeight="600" fontSize="45px">
                  {modalDeets?.challengeAmount &&
                    ethers?.utils.formatEther(
                      String(modalDeets?.challengeAmount)
                    )}{" "}
                  ETH
                </Box>
                <Box>
                  $
                  {Number(
                    Number(
                      Number(price) *
                        Number(
                          modalDeets?.challengeAmount &&
                            ethers?.utils.formatEther(
                              String(modalDeets?.challengeAmount)
                            )
                        )
                    )
                  )?.toFixed(3)}
                </Box>
              </VStack>
            </Box>

            <Box
              bg={getDetails(modalDeets?.state)?.color}
              p="10px"
              borderRadius="12px"
              w="100%"
              mt="10px"
            >
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Icon
                  color={getDetails(modalDeets?.state)?.iconColor}
                  boxSize="20px"
                  as={getDetails(modalDeets?.state)?.icon}
                />

                <Box color={getDetails(modalDeets?.state)?.iconColor}>
                  {" "}
                  {getDetails(modalDeets?.state)?.text}
                </Box>

                <InfoIcon
                  _hover={{ cursor: "pointer" }}
                  color={getDetails(modalDeets?.state)?.iconColor}
                />
              </Flex>
            </Box>

            <HStack
              mt="10px"
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <Box color="gray.500" _dark={{ color: "#7f7f7f" }}>
                Creator {getWording(modalDeets?.competitionType)}:
              </Box>
              <Box>
                {String(modalDeets?.creatorVolume)}{" "}
                {getWording(modalDeets?.competitionType) == "PnL" ? "bpu" : ""}
              </Box>
            </HStack>

            <HStack
              mt="10px"
              bg="#f5f5f7"
              _dark={{ bg: "#26272e" }}
              borderRadius="10px"
              p="10px"
            >
              <Box color="gray.500" _dark={{ color: "#7f7f7f" }}>
                Challenger {getWording(modalDeets?.competitionType)}:
              </Box>
              <Box>
                {String(modalDeets?.challengerVolume)}{" "}
                {getWording(modalDeets?.competitionType) == "PnL" ? "bpu" : ""}
              </Box>
            </HStack>

            <HStack
              display={
                String(modalDeets?.winner) ==
                "0x0000000000000000000000000000000000000000"
                  ? "none"
                  : "flex"
              }
              mt="10px"
              bg="#dcfaec"
              _dark={{ bg: "#dcfaec" }}
              borderRadius="10px"
              p="10px"
            >
              <Box color="black">Winner:</Box>
              <Box color="#00ca48">
                {String(modalDeets?.winner) == String(address)
                  ? "you"
                  : modalDeets?.winner?.slice(0, 7) +
                    "...." +
                    modalDeets?.winner?.slice(-6)}
              </Box>
            </HStack>

            <HStack
              display={String(modalDeets?.claimed) == "true" ? "flex" : "none"}
              mt="10px"
              bg="#00ca48"
              _dark={{ bg: "#00ca48" }}
              borderRadius="10px"
              p="10px"
            >
              <Box color="#dcfaec">Reward claimed</Box>
            </HStack>

            {/* <Link
              href={`/competitions/${modalDeets?.index}`}
              _hover={{ textDecoration: "none" }}
            > */}
            <Button
              onClick={() => handleCopyToClipboard()}
              w="100%"
              borderRadius="10px"
              mt="10px"
            >
              Share competition details
            </Button>
            {/*   </Link> */}
          </ModalBody>

          <ModalFooter alignItems="center" justifyContent="center">
            <Button
              borderRadius="10px"
              display={{ base: "flex", md: "none" }}
              w="100%"
              onClick={onClose}
            >
              Close overlay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Account;
