import { Box, Image, Flex, useColorMode, Center, Text } from "@chakra-ui/react";
import imglight from "../images/heroimglight.png";
import imgdark from "../images/heroimgdark.png";
import { useInView } from "framer-motion";
import React, { useRef } from "react";

const Hero = () => {
  const { colorMode } = useColorMode();
  const ref = useRef(null);
  const isInView = useInView(ref);

  const blurredCircleStyles = {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    height: "100%",
    borderRadius: "50%",
    filter: "blur(60px)",
    zIndex: 1,
    background: "linear-gradient(to top, #00a3ff, #00a3ff)",
  };

  return (
    <div ref={ref}>
      <Box
        mt="100px"
        textAlign="center"
        fontSize={{ base: "30px", md: "56px" }}
        style={{
          transform: isInView ? "none" : "translateY(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        <Box fontSize={{ base: "35px", md: "75px" }}>Match. Trade. Earn.</Box>
      </Box>
      <Flex
        style={{
          transform: isInView ? "none" : "translateY(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        mt="20px"
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        <Box
          color="gray.500"
          _dark={{ color: "white" }}
          w={{ base: "70%", md: "50%" }}
          textAlign="center"
        >
          MatchTrade is a GameFi-esque DEX experience offering peer-to-peer
          trading competitions where the winner takes all.
        </Box>
      </Flex>
      <Center
        style={{
          position: "relative",
          transform: isInView ? "none" : "translateY(200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        mt="60px"
      >
        <Box sx={blurredCircleStyles} />
        <Image
          pt="-2px"
          border={
            colorMode === "dark" ? "5px solid #111215" : "2px solid #f5f5f5"
          }
          borderRadius="20px"
          boxSize={{ base: "90%", md: "70%" }}
          src={colorMode == "light" ? imglight : imgdark}
          zIndex="2"
          position="relative"
        />
      </Center>
    </div>
  );
};

export default Hero;
