import React, { useState, useEffect } from "react";
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Flex,
  HStack,
  Box,
  Avatar,
  useColorMode,
  useToast,
  Alert,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import axios from "axios";
import { useAccount } from "wagmi";
import { fetchUserByAddress } from "../helpers/getUserData";

const AddToken = () => {
  const toast = useToast();
  const [userEyeDee, setUserEyeDee] = useState("");
  const [geckoTokens, setGeckoTokens] = useState([]);
  const [chosenObj, setChosenObj] = useState({});
  const [ca, setCa] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [details, setDetails] = useState({});
  const { colorMode } = useColorMode();
  const { address } = useAccount();

  const fetchTokenInfo = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://eth.llamarpc.com"
    );
    const provider2 = new ethers.providers.Web3Provider(window.ethereum);
    const abi = [
      "function symbol() view returns (string)",
      "function name() view returns (string)",
      "function decimals() view returns (uint8)",
    ];
    const tokenContract = new ethers.Contract(ca, abi, provider2);
    try {
      // Fetch token details
      const [symbol, name, decimals] = await Promise.all([
        tokenContract.symbol(),
        tokenContract.name(),
        tokenContract.decimals(),
      ]);

      setDetails({
        tokenName: name,
        tokenSymbol: symbol,
        tokenDecimals: decimals,
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchTokenInfo();
  }, [ca]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get("https://tokens.coingecko.com/uniswap/all.json")
          .then((data) => {
            setGeckoTokens(data?.data?.tokens);
          });
      } catch (e) {}
    };
    fetchData();
  }, []);

  const getGeckoInfo = (targetAddress) => {
    const foundObject = geckoTokens.find(
      (obj) =>
        String(obj.address?.toLowerCase()) ===
        String(targetAddress?.toLowerCase())
    );

    if (foundObject) {
      setChosenObj(foundObject);
    } else {
      setChosenObj("error");
    }
  };

  useEffect(() => {
    getGeckoInfo(ca);
  }, [ca, geckoTokens]);

  useEffect(() => {
    const getDetails = async () => {
      const eyedee = await fetchUserByAddress(address);
      setUserEyeDee(eyedee?._id);
    };
    getDetails();
  }, [address]);

  const addToken = async () => {
    try {
      const response = await axios.post(
        "https://serverrrrr-0tkv.onrender.com/api/addCustomToken",
        {
          userId: userEyeDee,
          customToken: {
            tokenAddress: ca,
            tokenSymbol: details?.tokenSymbol,
            tokenName: details?.tokenName,
            tokenDecimals: details?.tokenDecimals,
            chainId: 1,
            tokenLogo: chosenObj?.logoURI,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };

  //next, get all user custom tokens

  return (
    <div>
      <Button onClick={onOpen} size="sm" mr={3} borderRadius="10px">
        Add it
      </Button>

      <Modal
        size={{ base: "full", md: "sm" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="10px" _dark={{ bg: "#15161a" }}>
          <ModalHeader>Add a token </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="warning" mb="10px" borderRadius="10px">
              Tokens can be created by anyone, and fake tokens may have the same
              name and symbol as real tokens. Be careful and always confirm the
              contract address from an official source.
            </Alert>

            <Input
              bg="#f9f9f9"
              _dark={{ bg: "#2B2B2B" }}
              borderRadius="10px"
              placeholder="Enter token address"
              onChange={(e) => setCa(e?.target?.value)}
            />

            <Flex
              onClick={() => {
                addToken();
                toast({
                  title: "Custom token added",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
                onClose();
                /*                window.location.reload(); */
              }}
              display={Object.keys(details)?.length == 0 ? "none" : "flex"}
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              p="10px"
              mt="20px"
              _hover={{
                bg: colorMode == "dark" ? "#2B2B2B" : "#E9E9E9",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <HStack>
                <Avatar
                  name={details?.tokenName}
                  src={chosenObj?.logoURI}
                  boxSize="40px"
                />
                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Box fontWeight="600">{details?.tokenName}</Box>
                  <Box fontSize="13px">{details?.tokenSymbol}</Box>
                </Flex>
              </HStack>
            </Flex>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddToken;
