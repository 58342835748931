import {
  Box,
  Flex,
  Text,
  useColorMode,
  Button,
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import demo from "../images/matchtradeswapdemo.mov";
import demodark from "../images/matchtradeswapdemodark.mov";
import asset1 from "../images/asset1.png";
import asset2 from "../images/asset2.png";
import asset3 from "../images/asset3.png";
import asset4 from "../images/asset4.png";
import asset5 from "../images/asset5.png";
import asset6 from "../images/asset6.png";

const Section2 = () => {
  const { colorMode } = useColorMode();

  return (
    <div>
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Box mt={{ base: "700px", md: "500px" }}>
          <Text
            fontSize={{ base: "24px", md: "50px" }}
            fontWeight="600"
            textAlign="center"
          >
            Trade{" "}
            <Box
              as="span"
              display="inline-block"
              backgroundClip="text"
              color="transparent"
              backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
            >
              ultra-efficiently.
            </Box>
          </Text>
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="center"
            flexDir="column"
          >
            {" "}
            <Box
              color="gray.500"
              _dark={{ color: "white" }}
              mt="20px"
              textAlign="center"
              w={{ base: "90%", md: "70%" }}
            >
              Don't just compete. Trade ultra-efficiently on MatchDEX and
              benefit from advanced routing, MEV blocking and rugpull
              protection, all wrapped in a stupid-simple interface.
            </Box>
            <Button
              color="white"
              mt="20px"
              backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
              borderRadius="10px"
              size={{ base: "sm", md: "md" }}
              _hover={{
                backgroundImage:
                  "linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)",
              }}
            >
              Available on{" "}
              <Box ml="5px" as="span" display="inline-block">
                <HStack>
                  <Box>
                    <Image
                      boxSize="20px"
                      src="https://cdn.via.exchange/networks/base.svg"
                    />
                  </Box>
                  <Box>
                    <Image
                      boxSize="20px"
                      src="https://cdn.via.exchange/networks/Ethereum.svg"
                    />
                  </Box>
                </HStack>
              </Box>
            </Button>
            {/*           <Box
              border={
                colorMode === "dark" ? "5px solid #111215" : "2px solid #f5f5f5"
              }
              mt={{ base: "20px", md: "40px" }}
              w="90%"
              borderRadius="20px"
            >
              <div
                style={{
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <video width="100%" height="100px" autoPlay muted loop>
                  {colorMode == "light" ? (
                    <source src={demo} type="video/mp4" />
                  ) : (
                    <source src={demodark} type="video/mp4" />
                  )}
                  Your browser does not support the video tag.
                </video>
              </div>
            </Box> */}
            <Stack
              alignItems="center"
              justifyContent="center"
              mt="20px"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                w={{ base: "350px", md: "280px", lg: "380px" }}
                h="320px"
                borderRadius="20px"
                bg="none"
                border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
                _dark={{ bg: "#0d131e" }}
              >
                <Box>
                  <Image src={asset1} />
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    backgroundClip="text"
                    color="transparent"
                    backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
                    fontSize="30px"
                  >
                    Lightning fast.
                  </Box>
                </Flex>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  {" "}
                  <Box w="90%" textAlign="center" mt="10px">
                    Trade on Base or Ethereum in 30 seconds or less. Custom
                    routing logic allows us to always find the fastest route for
                    all your trades, regardless of size.
                  </Box>
                </Flex>
              </Box>

              <Box
                w={{ base: "350px", md: "280px", lg: "380px" }}
                h="320px"
                borderRadius="20px"
                bg="none"
                border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
                _dark={{ bg: "#0d131e" }}
              >
                <Box>
                  <Image src={asset2} />
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    backgroundClip="text"
                    color="transparent"
                    backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
                    fontSize="30px"
                  >
                    Unlimited liquidity.
                  </Box>
                </Flex>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  {" "}
                  <Box w="90%" textAlign="center" mt="10px">
                    MatchDEX can access over $2B in liquidity to facilitate
                    slippage-less swaps, regardless of swap size. If your size
                    is size, we've got you!
                  </Box>
                </Flex>
              </Box>
              <Box
                w={{ base: "350px", md: "280px", lg: "380px" }}
                h="320px"
                borderRadius="20px"
                bg="none"
                border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
                _dark={{ bg: "#0d131e" }}
              >
                <Box>
                  <Image src={asset3} />
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    backgroundClip="text"
                    color="transparent"
                    backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
                    fontSize="30px"
                  >
                    Safe Routing.
                  </Box>
                </Flex>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  {" "}
                  <Box w="90%" textAlign="center" mt="10px">
                    MatchDEX excludes paths that have low liquidity or high
                    potential of scamming. Users are always protected, and
                    security comes first.
                  </Box>
                </Flex>
              </Box>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              mt="20px"
              direction={{ base: "column", md: "row" }}
            >
              <Box
                w={{ base: "350px", md: "280px", lg: "380px" }}
                h="320px"
                borderRadius="20px"
                bg="none"
                border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
                _dark={{ bg: "#0d131e" }}
              >
                <Box>
                  <Image src={asset4} />
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    backgroundClip="text"
                    color="transparent"
                    backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
                    fontSize="30px"
                  >
                    Best Prices.
                  </Box>
                </Flex>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  {" "}
                  <Box w="90%" textAlign="center" mt="10px">
                    We aggregate over 50 sources including your favorite DEXs
                    and even cross-chain routes to ensure you always get the
                    best trades.
                  </Box>
                </Flex>
              </Box>

              <Box
                w={{ base: "350px", md: "280px", lg: "380px" }}
                h="320px"
                borderRadius="20px"
                bg="none"
                border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
                _dark={{ bg: "#0d131e" }}
              >
                <Box>
                  <Image src={asset5} />
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    backgroundClip="text"
                    color="transparent"
                    backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
                    fontSize="30px"
                  >
                    Custom Recipients.
                  </Box>
                </Flex>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  {" "}
                  <Box w="90%" textAlign="center" mt="10px">
                    MatchTrade allows you to privately swap your tokens without
                    anyone knowing where they end up, effectively acting as a
                    low level mixer.
                  </Box>
                </Flex>
              </Box>
              <Box
                w={{ base: "350px", md: "280px", lg: "380px" }}
                h="320px"
                borderRadius="20px"
                bg="none"
                border={colorMode === "light" ? "3px solid #f5f5f5" : "0px"}
                _dark={{ bg: "#0d131e" }}
              >
                <Box>
                  <Image src={asset6} />
                </Box>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    backgroundClip="text"
                    color="transparent"
                    backgroundImage="linear-gradient(280deg, rgb(0, 140, 255) 3.50796%, #00a3ff 40.6602%, #00a3ff 69.8058%, rgb(51, 207, 255) 95.409%)"
                    fontSize="30px"
                  >
                    Gas Efficient.
                  </Box>
                </Flex>
                <Flex w="100%" alignItems="center" justifyContent="center">
                  {" "}
                  <Box w="90%" textAlign="center" mt="10px">
                    MatchDEX takes the path of least resistance every time,
                    because time is money, and so is gas.
                  </Box>
                </Flex>
              </Box>
            </Stack>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default Section2;
