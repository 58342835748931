import React, { useState, useEffect } from "react";
import ConnectButton from "../components/ConnectButton";
import {
  Center,
  Box,
  Link,
  Flex,
  Image,
  Checkbox,
  HStack,
  Divider,
} from "@chakra-ui/react";
import logo from "../images/matchtrade.svg";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { fetchUserByAddress } from "../helpers/getUserData";
import DarkModeButton from "../components/DarkModeButton";

const Login = () => {
  const [accountDeets, setAccountDeets] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const { address } = useAccount();

  const navigate = useNavigate();

  const checkDeets = async () => {
    const deets = await fetchUserByAddress(address);
    setAccountDeets(deets);
  };

  useEffect(() => {
    if (address) {
      checkDeets();
    }
  }, [address]);

  useEffect(() => {
    if (accountDeets) {
      navigate("/app/portfolio");
    }
  }, [accountDeets]);

  return (
    <>
      <>
        <div>
          <div>
            <Flex
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              p="25px"
            >
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <HStack>
                  <Box>
                    {" "}
                    <Image
                      p="1px"
                      _dark={{ bg: "white", p: "5px" }}
                      borderRadius="5px"
                      boxSize="25px"
                      src={logo}
                    />
                  </Box>
                  <Box fontSize="20px"> MatchTrade</Box>
                </HStack>

                <DarkModeButton />
              </Flex>
            </Flex>
            <Flex flexDir={{ base: "column", md: "row" }} w="100%">
              <Box
                /*  display={{ base: "none", md: "yes" }} */
                borderRadius="15px"
                ml="25px"
                className="gradient-background"
                w={{ base: "85%", md: "30%" }}
                h={{ base: "60vh", md: "100vh" }}
              >
                <Center w="100%" color="white" h="100%">
                  <Image color="white" src={logo} boxSize="150px" />
                </Center>
              </Box>
              <Center
                mt={{ base: "-150px", md: "0px" }}
                w="100%"
                color="white"
                h="100vh"
              >
                <Flex
                  direction={{ base: "column", md: "row" }}
                  w={{ base: "80%", md: "40%" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box w={{ base: "100%", md: "50%" }}>
                    <Flex
                      flexDir="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      w="100%"
                    >
                      <Box>
                        <Box
                          color="black"
                          _dark={{ color: "white" }}
                          fontSize={{ base: "27px", md: "32px" }}
                        >
                          Hi again!
                        </Box>
                      </Box>

                      <Box mt="20px" w="100%">
                        <ConnectButton isDisabledd={isChecked} />
                      </Box>

                      <Box mt="20px" fontSize="12px">
                        <HStack color="black" _dark={{ color: "white" }}>
                          <Checkbox
                            isChecked={isChecked}
                            onChange={handleCheckboxChange}
                            colorScheme="blue"
                            borderRadius="10px"
                          />
                          <Box>
                            I agree to the{" "}
                            <Link href="/tos">
                              <Box
                                color="#00a3ff"
                                display="inline-block"
                                as="span"
                              >
                                {" "}
                                Terms and Conditions
                              </Box>
                            </Link>{" "}
                            ,{" "}
                            <Link href="/privacy">
                              <Box
                                color="#00a3ff"
                                display="inline-block"
                                as="span"
                              >
                                {" "}
                                Privacy Policy
                              </Box>
                            </Link>{" "}
                            and{" "}
                            <Link href="/disclaimer">
                              <Box
                                color="#00a3ff"
                                display="inline-block"
                                as="span"
                              >
                                {" "}
                                Risk Disclaimer
                              </Box>
                            </Link>
                          </Box>
                        </HStack>
                      </Box>

                      <Divider mt=" 20px" />
                      <Flex
                        color="black"
                        _dark={{ color: "white" }}
                        fontSize="15px"
                        mt="20px"
                        w="100%"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>No account?</Box>
                        <Link _hover={{ color: "#00a3ff" }} href="/app/signup">
                          {" "}
                          <Box>Sign up</Box>
                        </Link>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Center>
            </Flex>
          </div>
        </div>
      </>
    </>
  );
};

export default Login;
